import { useQuery } from '@apollo/client';
import { endOfToday, format, parseISO, startOfTomorrow } from 'date-fns';
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Col, Row } from 'react-grid-system';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import InterviewDetail from 'src/components/InterviewDetail';
import IntervieweeCard from 'src/components/IntervieweeCard';
import Modal from 'src/components/Modal';
import Text from 'src/components/Text';
import { InterviewContext } from 'src/contexts/InterviewContext';
import { GET_MY_PENDING_INTERVIEWS } from 'src/graphql/queries/myPendingInterviews';
import {
  MyPendingInterviews,
  MyPendingInterviews_myPendingInterviews_edges_node as Interview,
} from 'src/graphql/queries/__generated__/MyPendingInterviews';
import { CardsContainer, Container } from './PendingInterviews.styled';

interface Props {
  upcoming?: boolean;
}

const PendingInterviews: FC<Props> = ({ upcoming }) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { selectedInterview, setInterview } = useContext(InterviewContext);
  const [preSelectedInterview, setPreSelectedInterview] = useState<Interview>();
  const filters = upcoming ? { from: startOfTomorrow() } : { to: endOfToday() };
  const getInterviewsQuery = {
    query: GET_MY_PENDING_INTERVIEWS,
    variables: {
      first: 200,
      filters,
    },
  };

  const { loading, error, data } = useQuery<MyPendingInterviews>(
    getInterviewsQuery.query,
    { variables: getInterviewsQuery.variables },
  );

  const interviews = data?.myPendingInterviews.edges.map((edge) => edge.node);

  let lastDate: string;

  const handleSetInterview = useCallback(
    (interview: Interview | undefined) => {
      setInterview && setInterview(interview);
    },
    [setInterview],
  );

  useEffect(() => {
    // clear current interview when the view is loaded
    setInterview && setInterview(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcoming]);

  return (
    <Container>
      <Modal isOpen={modalOpen} onClose={() => null}>
        <ConfirmationDialog
          onSubmit={() => {
            handleSetInterview(preSelectedInterview);
            setModalOpen(false);
          }}
          closeModal={() => {
            setModalOpen(false);
          }}
          title="Please confirm"
          primaryButtonText="Confirm"
        >
          Your unsaved data will be lost. Are you sure you want to continue?
        </ConfirmationDialog>
      </Modal>

      {loading ? (
        <Text preset="preset5">Loading...</Text>
      ) : error ? (
        <Text preset="preset5">
          There was an error loading your data, please try again later.
        </Text>
      ) : !interviews?.length ? (
        <Text preset="preset5">
          No {upcoming ? 'upcoming' : 'pending'} interviews.
        </Text>
      ) : (
        <Row gutterWidth={16}>
          <Col xl={4}>
            <Text
              preset="preset3"
              marginBottom={12}
              semiBold
              color="var(--color-ink-primary)"
            >
              {interviews?.length} pending interview
              {interviews?.length !== 1 ? 's' : ''}
            </Text>

            <CardsContainer>
              {interviews.map((interview) => {
                const currentDate = format(
                  parseISO(interview.startDateTime),
                  'MMM d, y',
                );
                const isDifferentDate = lastDate !== currentDate;
                lastDate = currentDate;

                return (
                  <Fragment key={interview.id}>
                    {isDifferentDate && (
                      <Text
                        preset="preset4"
                        color="var(--color-ink-notasdark)"
                        marginBottom={8}
                        semiBold
                      >
                        {currentDate}
                      </Text>
                    )}

                    <IntervieweeCard
                      selected={interview.id === selectedInterview?.id}
                      interview={interview}
                      onChange={(i) => {
                        if (i?.id !== selectedInterview?.id && isDirty) {
                          setModalOpen(true);
                          setPreSelectedInterview(i);
                        } else {
                          handleSetInterview(i);
                        }
                      }}
                    />
                  </Fragment>
                );
              })}
            </CardsContainer>
          </Col>

          <Col xl={8}>
            <InterviewDetail
              setIsDirty={setIsDirty}
              interview={selectedInterview}
              setInterview={handleSetInterview}
              refetchInterviewsQuery={getInterviewsQuery}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default PendingInterviews;
