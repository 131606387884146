import * as Sentry from '@sentry/browser';
import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from 'src/components/PrivateRoute';
import PublicRoute from 'src/components/PublicRoute';
import AuthContextProvider from 'src/contexts/AuthContext';
import InterviewContextProvider from 'src/contexts/InterviewContext';
import AllInterviews from 'src/views/AllInterviews';
import LoginView from 'src/views/Login';
import PasswordReset from 'src/views/PasswordReset';
import PendingInterviews from 'src/views/PendingInterviews';
import Schedule from 'src/views/Schedule';
import SignUpView from 'src/views/SignUp';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const App: React.FC = () => {
  return (
    <AuthContextProvider>
      <InterviewContextProvider>
        <Router>
          <Switch>
            <PublicRoute exact path="/signin">
              <LoginView />
            </PublicRoute>

            <PublicRoute exact path="/password_reset">
              <PasswordReset />
            </PublicRoute>

            <PublicRoute exact path="/accept_invitation">
              <SignUpView />
            </PublicRoute>

            <PrivateRoute exact path="/schedule">
              <Schedule />
            </PrivateRoute>

            <PrivateRoute exact path="/upcoming-interviews">
              <PendingInterviews upcoming />
            </PrivateRoute>

            <PrivateRoute exact path="/all-interviews">
              <AllInterviews />
            </PrivateRoute>

            <PrivateRoute>
              <Redirect to="/schedule" />;
            </PrivateRoute>
          </Switch>
        </Router>
      </InterviewContextProvider>
    </AuthContextProvider>
  );
};

export default App;
