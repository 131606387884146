import React from 'react';

import Text, { TextProps } from 'src/components/Text';
import { Container } from './TextBanner.styled';

type Props = {
  color: string;
  text: string;
} & TextProps;

const TextBanner: React.FC<Props> = ({ children, text, color, ...props }) => {
  return (
    <Container color={color}>
      <Text {...props}>{text}</Text>
      {children}
    </Container>
  );
};

export default TextBanner;
