import React, { FC, useState } from 'react';
import Select from 'src/components/Select';
import { useDefaultPositionQuery } from 'src/graphql/queries/GetDefaultPositions';
import styled from 'styled-components';

const SelectContainer = styled.div`
  width: 50%;
  padding: 12px 0;
`;

type Props = {
  onChange: (selectedPositionsId: string) => void;
  onlyInterviewable?: boolean;
  hiddenIds?: string[];
};

const DefaultPositionsInput: FC<Props> = ({
  onChange,
  onlyInterviewable,
  hiddenIds,
}) => {
  const { data } = useDefaultPositionQuery();
  const [selectedPositionId, setSelectedPositionId] = useState<string>();
  if (!data) {
    return null;
  }
  const options = data.defaultPositions.reduce(
    (filtered: { label: string; value: string }[], position) => {
      const shouldDisplayPosition =
        !hiddenIds?.includes(position.id) &&
        ((onlyInterviewable && position.category === 'INTERVIEW') ||
          !onlyInterviewable);
      if (shouldDisplayPosition) {
        filtered.push({
          label: position.name,
          value: position.id,
        });
      }
      return filtered;
    },
    [],
  );
  return (
    <SelectContainer>
      <Select
        options={options || []}
        value={selectedPositionId}
        onChange={(selectedItem) => {
          if (selectedItem) {
            onChange(selectedItem);
            setSelectedPositionId(selectedItem);
          }
        }}
      />
    </SelectContainer>
  );
};

export default DefaultPositionsInput;
