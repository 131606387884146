import gql from 'graphql-tag';

export const GET_POSITION_SKILLS = gql`
  query GetPositionSkills(
    $positionId: ID!
    $paginationOptions: PaginationOptions!
  ) {
    positionSkills(positionId: $positionId, pagination: $paginationOptions) {
      items {
        id
        key
        name
      }
    }
  }
`;
