import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { DefaultPositions } from './__generated__/DefaultPositions';

export const DEFAULT_POSITIONS_QUERY = gql`
  query DefaultPositions {
    defaultPositions {
      id
      name
      category
    }
  }
`;

export const useDefaultPositionQuery = (
  options?: QueryHookOptions<DefaultPositions>,
) => useQuery<DefaultPositions>(DEFAULT_POSITIONS_QUERY, options);

export default DEFAULT_POSITIONS_QUERY;
