import styled from 'styled-components';
import { Container as RGContainer } from 'react-grid-system';

export const CardsContainer = styled.div`
  padding: 24px 16px 2px;
  background-color: ${({ theme }) => theme.color.backgroundSurface};
`;

export const Container = styled(RGContainer)`
  padding-top: 64px;
`;
