import React, { FC } from 'react';
import styled from 'styled-components';
import { default as RadioMaterial, RadioProps } from '@material/react-radio';

const RadioContainer = styled.div<{ inline: boolean }>`
  ${({ inline }) =>
    inline
      ? `
    margin-left: 14px;
    .mdc-form-field > label {
      padding: 0;
    }
    `
      : `
    .mdc-form-field {
      flex-direction: column;
    }
  `}
`;

type Props = Pick<RadioProps, 'children' | 'label'> & { inline?: boolean };

const Radio: FC<Props> = ({ inline = false, ...props }) => (
  <RadioContainer inline={inline}>
    <RadioMaterial {...props} />
  </RadioContainer>
);

export default Radio;
