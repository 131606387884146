import gql from 'graphql-tag';

export const GET_TENDER_ASSESSMENT = gql`
  query TenderAssessment($positionId: ID!) {
    tenderAssessment(id: $positionId) {
      id
      profilePictureValidated
      finalNotes
      assessmentOutcome
      completedAt
      processed
      createdAt
      updatedAt
      tender {
        id
        email
        lastName
        preferredName
        phoneNumber
        avatarURL
        status
      }
      positionApplications {
        id
        status
        position {
          id
          name
        }
        positionSkillRanks {
          id
          positionSkillId
          rank
        }
        experienceEntries {
          id
          startDate
          endDate
          employer
          jobType
          businessType {
            id
            name
          }
          validated
        }
        preinterviewAnswers {
          id
          question {
            text
            title
          }
          answers
        }
      }
    }
  }
`;
