import { Col, Row } from 'react-grid-system';
import { useMutation } from '@apollo/client';
import { addMinutes, format, parseISO } from 'date-fns';
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import InterviewDetail from 'src/components/InterviewDetail';
import IntervieweeCard from 'src/components/IntervieweeCard';
import Modal from 'src/components/Modal';
import Text from 'src/components/Text';
import { InterviewContext, InterviewType } from 'src/contexts/InterviewContext';
import SearchTender from 'src/components/SearchTender';
import Snackbar from 'src/components/Snackbar';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';
import {
  CardsContainer,
  Container,
  SubmitButton,
} from './AllInterviews.styled';
import SEARCH_PENDING_INTERVIEWS, {
  useSearchPendingInterviewsLazyQuery,
} from '../../graphql/queries/SearchPendingInterviews';
import { Tenders_tenders_items as Tender } from '../../graphql/queries/__generated__/Tenders';
import { CREATE_SCHEDULED_INTERVIEW_AS_ADMIN } from '../../graphql/mutations/createScheduledInterviewAsAdmin';
import { BlackBorderedButton } from '../../components/Button';
import { PullRight } from '../../components/ConfirmationDialog/ConfirmationDialog';

interface Props {
  upcoming?: boolean;
}

const AllInterviews: FC<Props> = ({ upcoming }) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedTender, setSelectedTender] = useState<Tender | undefined>(
    undefined,
  );
  const { selectedInterview, setInterview } = useContext(InterviewContext);
  const [preSelectedInterview, setPreSelectedInterview] =
    useState<InterviewType>();
  const [snackMessage, setSnackMessage] = useState<string>('');
  const [nextCursor, setNextCursor] = useState<string>('');
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [previousCursor, setPreviousCursor] = useState<string>('');
  const [previousCursorMap, setPreviousCursorMap] = useState(new Map());
  const updatePreviousCursorMap = (k: string, v: string) => {
    setPreviousCursorMap(new Map(previousCursorMap.set(k, v)));
  };
  const resetPreviousCursorMap = () => {
    setNextCursor('');
    setPreviousCursor('');
    setPreviousCursorMap(new Map());
  };

  const updatePaginator = (pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
  }) => {
    const endCursor = pageInfo.endCursor;
    if (previousCursorMap.has(endCursor)) {
      setPreviousCursor(previousCursorMap.get(endCursor));
    } else {
      setPreviousCursor(nextCursor);
    }

    setHasNextPage(pageInfo.hasNextPage);
    setNextCursor(endCursor);
  };

  const listSize = 40;
  let lastDate: string;

  const selectedTenderInfo = selectedTender
    ? `${selectedTender.firstName} ${selectedTender.lastName}`
    : '';

  // This is required in interviewDetail, but it's not used here
  const searchPendingInterviewsQuery = {
    query: SEARCH_PENDING_INTERVIEWS,
    variables: { first: listSize },
  };

  const [createScheduledInterviewAsAdmin] = useMutation(
    CREATE_SCHEDULED_INTERVIEW_AS_ADMIN,
  );

  const [searchPendingInterviews, { loading, error, data }] =
    useSearchPendingInterviewsLazyQuery();

  const interviews = data?.searchPendingInterviews.edges.map(
    (edge) => edge.node,
  );

  const handleSetInterview = useCallback(
    (interview: InterviewType | undefined) => {
      setInterview && setInterview(interview);
    },
    [setInterview],
  );

  useEffect(() => {
    // list all pending interviews
    reloadInterviewsList();
    // clear current interview when the view is loaded
    setInterview && setInterview(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcoming]);

  // After loading interviews, select again the interview that was selected before
  useEffect(() => {
    if (selectedInterview && interviews?.length) {
      const interviewId = selectedInterview.id;
      const updatedInterview = interviews.find(
        (interview) => interview.id === interviewId,
      );
      updatedInterview && setInterview && setInterview(updatedInterview);
    }

    data?.searchPendingInterviews?.pageInfo &&
      updatePaginator(data.searchPendingInterviews.pageInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    updatePreviousCursorMap(nextCursor, previousCursor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextCursor]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedInterview]);

  const reloadInterviewsList = (
    tenderId: string | undefined = undefined,
    useCursor: boolean | undefined = true,
  ) => {
    searchPendingInterviews({
      variables: {
        first: listSize,
        ...(useCursor && previousCursor ? { after: previousCursor } : {}),
        ...(tenderId ? { tenderId } : {}),
      },
    });
  };

  const handlePaginatorNext = () => {
    setInterview && setInterview(undefined);
    searchPendingInterviews({
      variables: { first: listSize, after: nextCursor },
    });
  };

  const handlePaginatorBack = () => {
    setInterview && setInterview(undefined);
    const _previousCursor = previousCursorMap.get(previousCursor);
    searchPendingInterviews({
      variables: { first: listSize, after: _previousCursor },
    });
  };

  const handleReloadInterviewsList = () => {
    if (selectedTender) {
      handleSelectTender(selectedTender);
    } else {
      reloadInterviewsList();
    }
  };

  const handleSelectTender = (tender: Tender) => {
    setSelectedTender(tender);
    reloadInterviewsList(tender.id, false);
  };

  const handleCloseSearchTenderPopover = () => {
    setSelectedTender(undefined);
    setInterview && setInterview(undefined);
    resetPreviousCursorMap();
    reloadInterviewsList(undefined, false);
  };

  const handleApproveOrDenyAction = (message: string | undefined) => {
    resetPreviousCursorMap();
    reloadInterviewsList(undefined, false);
    message && setSnackMessage(message);
  };

  const handleCancelInterviewAction = () => {
    setInterview && setInterview(undefined);
    resetPreviousCursorMap();
    reloadInterviewsList(undefined, false);
  };

  const handleCreateNewInterview = async () => {
    if (!selectedTender) {
      return;
    }

    // Create a new interview, timezone is not important
    const startDate = new Date();
    const result = await createScheduledInterviewAsAdmin({
      variables: {
        input: {
          tenderId: selectedTender.id,
          startDateTime: new Date(),
          endDateTime: addMinutes(startDate, 15),
          timezone: 'America/Los_Angeles',
        },
      },
    });

    if (result.data) {
      setInterview && setInterview(undefined);
      handleSelectTender(selectedTender);
    }
  };

  return (
    <Container>
      <Modal isOpen={modalOpen} onClose={() => null}>
        <ConfirmationDialog
          onSubmit={() => {
            handleSetInterview(preSelectedInterview);
            setModalOpen(false);
          }}
          closeModal={() => {
            setModalOpen(false);
          }}
          title="Please confirm"
          primaryButtonText="Confirm"
        >
          Your unsaved data will be lost. Are you sure you want to continue?
        </ConfirmationDialog>
      </Modal>

      <Row gutterWidth={16}>
        <Col xl={4}>
          <SearchTender
            selectTender={handleSelectTender}
            closePopover={handleCloseSearchTenderPopover}
          />
        </Col>
      </Row>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <Text preset="preset5">
          There was an error loading your data, please try again later.
        </Text>
      ) : !interviews?.length ? (
        <Text preset="preset5">
          No pending interviews
          <br />
          {!!selectedTender && (
            <SubmitButton
              onClick={(event) => {
                event.preventDefault();
                handleCreateNewInterview();
              }}
            >
              Create new interview for {selectedTenderInfo}
            </SubmitButton>
          )}
        </Text>
      ) : (
        <Row gutterWidth={16}>
          <Col xl={4}>
            <CardsContainer>
              {interviews.map((interview) => {
                const currentDate = format(
                  parseISO(interview.createdAt),
                  'MMM d, y',
                );
                const isDifferentDate = lastDate !== currentDate;
                lastDate = currentDate;

                return (
                  <Fragment key={interview.id}>
                    {isDifferentDate && (
                      <Text
                        preset="preset4"
                        color="var(--color-ink-notasdark)"
                        marginBottom={8}
                        semiBold
                      >
                        {currentDate}
                      </Text>
                    )}

                    <IntervieweeCard
                      selected={interview.id === selectedInterview?.id}
                      interview={interview}
                      onChange={(i) => {
                        if (i?.id !== selectedInterview?.id && isDirty) {
                          setModalOpen(true);
                          setPreSelectedInterview(i);
                        } else {
                          handleSetInterview(i);
                        }
                      }}
                    />
                  </Fragment>
                );
              })}
              <PullRight>
                <BlackBorderedButton
                  outlined
                  onClick={handlePaginatorBack}
                  disabled={!previousCursor}
                >
                  Previous
                </BlackBorderedButton>
                <BlackBorderedButton
                  outlined
                  onClick={handlePaginatorNext}
                  disabled={!nextCursor || !hasNextPage}
                >
                  Next
                </BlackBorderedButton>
              </PullRight>
            </CardsContainer>
          </Col>

          <Col xl={8}>
            <>
              {snackMessage && (
                <Snackbar
                  timeoutMs={5000}
                  message={snackMessage}
                  actionText="×"
                  onClose={() => {
                    setSnackMessage('');
                  }}
                />
              )}
              <InterviewDetail
                setIsDirty={setIsDirty}
                interview={selectedInterview}
                setInterview={handleSetInterview}
                refetchInterviewsQuery={searchPendingInterviewsQuery}
                handleApproveOrDenyAction={handleApproveOrDenyAction}
                handleCancelInterviewAction={handleCancelInterviewAction}
                handleReloadInterviewsList={handleReloadInterviewsList}
              />
            </>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AllInterviews;
