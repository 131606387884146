import React from 'react';
import styled from 'styled-components';
import Text from 'src/components/Text';
import { BlackBorderedButton, DangerButton } from 'src/components/Button';

export const PullRight = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 32px;
  ${BlackBorderedButton} {
    margin-right: 16px;
  }
`;

type Props = {
  title: string;
  closeModal: () => void;
  onSubmit: () => void;
  primaryButtonText: string;
  disabled?: boolean;
};

export const ConfirmationDialog: React.FC<Props> = ({
  title,
  children,
  closeModal,
  onSubmit,
  primaryButtonText,
  disabled,
}) => {
  return (
    <>
      <Text preset="preset3" bold>
        {title}
      </Text>
      {children}
      <PullRight>
        <BlackBorderedButton
          outlined
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            closeModal();
          }}
        >
          Cancel
        </BlackBorderedButton>
        <DangerButton onClick={onSubmit} disabled={disabled}>
          {primaryButtonText}
        </DangerButton>
      </PullRight>
    </>
  );
};
