import React, { FC, useState } from 'react';
import useDebounce from 'src/hooks/useDebounce';
import { Tenders_tenders_items as Tender } from '../../graphql/queries/__generated__/Tenders';
import TenderCard from '../TenderCard';
import {
  Container,
  Error,
  Icon,
  SearchInput,
  Popover,
  Loading,
} from './SearchTender.styled';
import { useTendersLazyQuery } from '../../graphql/queries/tenders';
import { FilterOperator } from '../../__generated__/globalTypes';

type Props = {
  placeholder?: string;
  selectTender?: (tender: Tender) => void;
  closePopover?: () => void;
};

const SearchTender: FC<Props> = ({
  placeholder = 'Search tenders',
  selectTender,
  closePopover,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [tenders, { data, loading, error }] = useTendersLazyQuery();

  const hasResults = data && data?.tenders.items.length > 0;

  const debouncedSearch = useDebounce(() => {
    if (!inputValue) {
      handlePopoverClose();
      return;
    }

    tenders({
      variables: {
        filters: [
          {
            property: 'searchTerm',
            operator: FilterOperator.equals,
            values: [inputValue],
          },
        ],
      },
    });

    setIsTyping(false);
  }, 300);

  const isOpen = !!inputValue || !!error || !!loading;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsTyping(true);
    debouncedSearch();
  };

  const handleSelect = (tender: Tender) => {
    handlePopoverClose();
    selectTender?.(tender);
  };

  const handlePopoverClose = () => {
    setInputValue('');
    closePopover?.();
  };

  return (
    <Container>
      <Icon icon="search" />

      <SearchInput
        type="search"
        placeholder={placeholder}
        onChange={handleInputChange}
      />

      <Popover open={isOpen}>
        {loading && <Loading>Searching...</Loading>}

        {!loading && !hasResults && !isTyping && (
          <Error>Couldn't find any tender that match your query.</Error>
        )}

        {!loading && !!error && <Error>{error}</Error>}

        {!loading &&
          hasResults &&
          data?.tenders.items.map((tender) => {
            return (
              <TenderCard
                key={tender.id}
                tender={tender}
                onChange={handleSelect}
              />
            );
          })}
      </Popover>
    </Container>
  );
};

export default SearchTender;
