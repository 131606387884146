import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: InterviewerPasswordChangeInput!) {
    changeInterviewerPassword(input: $input) {
      id
      email
    }
  }
`;
