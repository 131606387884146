import React from 'react';
import styled from 'styled-components';

export type Preset =
  | 'preset1'
  | 'preset2'
  | 'preset3'
  | 'preset4'
  | 'preset5'
  | 'preset5P'
  | 'preset6'
  | 'preset6P'
  | 'preset7'
  | 'preset7P';

export type TextProps = {
  preset: Preset;
  semiBold?: boolean;
  bold?: boolean;
  bolder?: boolean;
  color?: string;
  className?: string;
  margin?: string;
  marginBottom?: number;
};

const TextComponent = styled.p<TextProps>`
  ${({ preset, theme }) => theme.text[preset]};
  color: ${({ color, theme }) => color || theme.color.inkDark};
  font-weight: ${({ bolder, bold, semiBold }) =>
    bolder ? '700' : bold ? '600' : semiBold ? '500' : '400'};
  margin: ${({ margin }) => margin || 0};
  ${({ margin, marginBottom }) =>
    marginBottom && !margin ? `margin-bottom: ${marginBottom}px` : ''};
`;

// boldest ? '700' : bolder ? '600' : bold ? '500' : '400'};
const Text: React.FC<TextProps> = ({ children, ...props }) => (
  <TextComponent {...props}>{children}</TextComponent>
);

export default Text;
