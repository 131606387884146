import styled from 'styled-components';
import Text from 'src/components/Text';

interface Props {
  required?: boolean;
  bolder?: boolean;
}

const Label = styled(Text)<Props>`
  margin-bottom: 7px;
  ${({ required, theme }) =>
    required &&
    `
    &::before {
      content: "* ";
      color: ${theme.color.statusDestructive};
      font-weight: 600;
    }
  `}
`;

export default Label;
