import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { validateUnauthState } from 'src/auth';
import { authContext } from 'src/contexts/AuthContext';

const PublicRoute: React.FC<RouteProps> = (props) => {
  const auth = useContext(authContext);
  const history = useHistory();
  if (validateUnauthState(history.location, auth.authToken)) {
    return <Route {...props} />;
  }
  return <Redirect to="/schedule" />;
};

export default PublicRoute;
