import styled from 'styled-components';
import { Container as RGContainer } from 'react-grid-system';
import { SubmitButton as GreenButton } from '../../components/Button';

export const CardsContainer = styled.div`
  padding: 24px 16px 10px;
  background-color: ${({ theme }) => theme.color.backgroundSurface};
`;

export const Container = styled(RGContainer)`
  padding-top: 64px;
`;

export const SubmitButton = styled(GreenButton)`
  margin-top: 16px;
`;
