import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { validateAuthState } from 'src/auth';
import { authContext } from 'src/contexts/AuthContext';
import TopNavigation from 'src/components/TopNavigation';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const auth = useContext(authContext);
  const history = useHistory();
  if (validateAuthState(history.location, auth.authToken)) {
    return (
      <>
        <TopNavigation />
        <Route {...props} />
      </>
    );
  }
  return <Redirect to="/signin" />;
};

export default PrivateRoute;
