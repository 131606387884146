import React, { useEffect, useState } from 'react';
import { NativeRadioControl } from '@material/react-radio';
import styled from 'styled-components';

import Radio from 'src/components/Radio';

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  disabled?: boolean;
  onChange?: (key: string | number | boolean) => void;
  options: {
    value: string | number;
    label: string;
    'data-cy'?: string;
  }[];
  inline?: boolean;
  value?: string | number | boolean;
  defaultValue?: string | number | boolean;
};

const RadioGroup: React.FC<Props> = ({
  onChange,
  options,
  value,
  inline = false,
  disabled = false,
  defaultValue,
}) => {
  const [selected, setSelected] = useState<
    string | number | boolean | undefined
  >(value?.toString() || defaultValue?.toString() || undefined);

  useEffect(() => {
    if (value !== selected) {
      setSelected(value?.toString());
    }
  }, [selected, value]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const key = event.currentTarget.value;
    if (key) {
      setSelected(key);
      if (onChange) {
        let newValue: boolean | string | number = key;
        if (key === 'true') {
          newValue = true;
        } else if (key === 'false') {
          newValue = false;
        } else if (!isNaN(Number(key)) && !isNaN(parseInt(key, 10))) {
          newValue = parseInt(key, 10);
        }
        onChange(newValue);
      }
    }
  };

  return (
    <>
      {options.map((option) => (
        <Radio inline={inline} label={option.label} key={option.value}>
          <NativeRadioControl
            disabled={disabled}
            onChange={handleChange}
            checked={selected === option.value.toString()}
            value={option.value}
            data-cy={option['data-cy']}
          />
        </Radio>
      ))}
    </>
  );
};

export default RadioGroup;
