import React from 'react';
import styled from 'styled-components';
import Text, { TextProps } from 'src/components/Text';

type GreyRectangleProps = {
  width: number;
  height?: number;
};
type MaybeEmptyTextProps = TextProps & {
  visible: boolean;
  width: number;
};
const GreyRectangle = styled.div<GreyRectangleProps>`
  background-color: ${({ theme }) => theme.color.primaryLight};
  height: ${({ height }) => (height ? height : '12')}px;
  width: ${({ width }) => `${width}px`};
  margin-bottom: 4px;
`;

const MaybeEmptyText: React.FC<MaybeEmptyTextProps> = ({
  visible,
  width,
  ...props
}) => {
  if (!visible) {
    return <GreyRectangle width={width} />;
  }
  return <Text {...props} />;
};

export default MaybeEmptyText;
