import React, { FC } from 'react';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';
import Text from 'src/components/Text';

interface Props {
  block?: boolean;
  visible?: boolean;
}

const BlockErrorContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.statusDestructive};
  border-radius: 4px;
  padding: 12px;
  .icon {
    color: ${({ theme }) => theme.color.inkClear};
    margin-right: 8px;
  }
`;

const ErrorMessage: FC<Props> = ({ block, children, visible = true }) => {
  const content = children || 'Please fill all required fields.';
  if (!visible) {
    return null;
  }
  if (block) {
    return (
      <BlockErrorContainer>
        <MaterialIcon icon="error_outline" className="icon" />
        <Text preset="preset5P" color="var(--color-ink-clear)">
          {content}
        </Text>
      </BlockErrorContainer>
    );
  }
  return (
    <Text
      preset="preset5P"
      color="var(--color-status-destructive)"
      margin="0"
      semiBold
    >
      {content}
    </Text>
  );
};
export default ErrorMessage;
