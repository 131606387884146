import styled from 'styled-components';

export const AvatarPlaceholder = styled.div`
  background-color: ${({ theme }) => theme.color.primaryLight};
  height: 100%;
  border-radius: 50%;
  align-self: center;
  margin-bottom: 16px;
`;

export const AvatarLabel = styled.p<{ bigger: boolean }>`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ bigger }) => (bigger ? 48 : 20)}px;
  line-height: 28px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 0;
  padding-top: ${({ bigger }) => (bigger ? 36 : 10)}px;
`;

type ContainerProps = { size: number; paddingTop?: boolean };

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  padding-top: ${({ paddingTop }) => paddingTop && 24}px;
`;

export const Image = styled.img`
  border: 1px solid ${({ theme }) => theme.color.primaryLight};
  border-radius: 50%;
  height: 100%;
  width: 100%;
`;
