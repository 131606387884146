import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { SubmitButton } from 'src/components/Button';
import { ErrorMessage } from 'src/components/StatusMessages';
import Label from 'src/components/Label';
import PasswordInput from 'src/components/PasswordInput';
import { authContext } from 'src/contexts/AuthContext';
import { CHANGE_PASSWORD } from 'src/graphql/mutations/changeInterviewerPassword';

const CustomPasswordInput = styled(PasswordInput)`
  width: 45%;
  text-align: left;
  display: flex;
`;

const StatusMessage = styled(ErrorMessage)`
  color: var(--color-ink-notasdark);
`;

const Container = styled.div`
  margin-bottom: 20px;
`;

const Form = styled.form`
  margin: 48px auto;
  width: 368px;
`;

type FormValues = {
  newPassword: string;
  passwordConfirmation: string;
};

const PasswordChangeSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Password must not be blank')
    .min(8, 'Your password must be at least 8 characters.')
    .max(255, 'Your password must be less than 255 characters.'),
  passwordConfirmation: Yup.string()
    .required('Password confirmation must not be blank')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

interface PasswordResetProps {
  token: string;
}

const PasswordResetForm: React.FC<PasswordResetProps> = (
  passwordResetProps,
) => {
  const { token: temporaryToken } = passwordResetProps;

  const auth = useContext(authContext);

  const {
    control,
    formState: { errors },
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(PasswordChangeSchema),
  });

  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const onSubmit = async (values: FormValues) => {
    auth.setAuthToken(temporaryToken);
    setSuccessMessage(undefined);
    setErrorMessage(undefined);

    try {
      const result = await changePassword({
        variables: { input: { password: values.newPassword } },
      });

      if (result.data.changeUserPassword) {
        setSuccessMessage(
          'Password changed successfully. Please sign in with your new password.',
        );
      }
    } catch (error) {
      setErrorMessage(
        'The link you just used is either invalid or has already expired. Please obtain a new verification email.',
      );
    } finally {
      // remove token regardless of the outcome
      // if it's invalid then there's no use in keeping it
      // and if it's valid we should still remove it, since we want to enforce users to sign in with the new password
      auth.removeAuthToken();
    }

    reset({
      newPassword: '',
      passwordConfirmation: '',
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Label preset="preset5" required>
        New Password
      </Label>
      <Controller
        name="newPassword"
        control={control}
        render={({ field: { ref, ...props } }) => (
          <CustomPasswordInput
            isValid={!errors.newPassword}
            label=" "
            {...props}
          />
        )}
      />
      {errors.newPassword && (
        <FormErrorMessage
          errors={errors}
          name="newPassword"
          as={ErrorMessage}
        />
      )}
      <Label preset="preset5" required>
        Confirm Password
      </Label>
      <Controller
        name="passwordConfirmation"
        control={control}
        render={({ field: { ref, ...props } }) => (
          <CustomPasswordInput
            label=" "
            isValid={!errors.passwordConfirmation}
            {...props}
          />
        )}
      />
      {errors.passwordConfirmation && (
        <FormErrorMessage
          errors={errors}
          name="passwordConfirmation"
          as={ErrorMessage}
        />
      )}
      <Container>
        {successMessage && <StatusMessage>{successMessage}</StatusMessage>}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Container>
      <Container>
        <SubmitButton type="submit" disabled={isSubmitting}>
          Change Password
        </SubmitButton>
      </Container>
    </Form>
  );
};

export default PasswordResetForm;
