import React, { createContext, useState } from 'react';
import { MyInterviews_myScheduledInterviews_edges_node as ScheduledMyInterview } from 'src/graphql/queries/__generated__/MyInterviews';
import { SearchPendingInterviews_searchPendingInterviews_edges_node as SearchPendingInterview } from 'src/graphql/queries/__generated__/SearchPendingInterviews';
import { MyPendingInterviews_myPendingInterviews_edges_node as MyPendingInterview } from 'src/graphql/queries/__generated__/MyPendingInterviews';

export type InterviewType =
  | ScheduledMyInterview
  | MyPendingInterview
  | SearchPendingInterview
  | undefined;

interface InterviewContextProps {
  selectedInterview: InterviewType;
  setInterview?: (
    interview:
      | ScheduledMyInterview
      | MyPendingInterview
      | SearchPendingInterview
      | undefined,
  ) => void;
}

export const InterviewContext = createContext<InterviewContextProps>({
  selectedInterview: undefined,
});

const InterviewContextProvider: React.FC = ({ children }) => {
  const [selectedInterview, setSelectedInterview] = useState<InterviewType>();

  const setInterview = (interview: InterviewType | undefined) => {
    setSelectedInterview(interview);
  };

  return (
    <InterviewContext.Provider value={{ selectedInterview, setInterview }}>
      {children}
    </InterviewContext.Provider>
  );
};

export default InterviewContextProvider;
