import gql from 'graphql-tag';
import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { Tenders, TendersVariables } from './__generated__/Tenders';

export const TENDERS = gql`
  query Tenders($filters: [Filter!]) {
    tenders(
      filters: $filters
      paginationOptions: { page: 1, limit: 10 }
      includeBlockedTenders: false
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        avatarURL
        status
        createdAt
        updatedAt
        address {
          city
          state
        }
        score
      }
    }
  }
`;

export const useTendersLazyQuery = (
  options?: QueryHookOptions<Tenders, TendersVariables>,
) => useLazyQuery<Tenders, TendersVariables>(TENDERS, options);

export default TENDERS;
