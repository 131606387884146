import React, { useContext } from 'react';
import { authContext } from 'src/contexts/AuthContext';
import Logo from 'src/images/tend-logo.svg';
import {
  BurgerLine,
  Button,
  CenteredContainer,
  CenteredLink,
  Container,
  Divider,
  ImgLogo,
  MenuContainer,
  MenuItem,
  RightContainer,
  StyledBurger,
  Tab,
  TabBar,
} from './TopNavigation.styled';

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

const HamburgerMenu: React.FC<Props> = ({ children, isOpen, onClick }) => {
  return (
    <>
      <StyledBurger onClick={onClick}>
        <BurgerLine isOpen={isOpen} />
        <BurgerLine isOpen={isOpen} />
        <BurgerLine isOpen={isOpen} />
      </StyledBurger>
      {children}
    </>
  );
};

const TopNavigation: React.FC = () => {
  const auth = useContext(authContext);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Container>
      <CenteredContainer>
        <CenteredLink to="/">
          <ImgLogo src={Logo} />
        </CenteredLink>

        <TabBar>
          <Tab to="/schedule">Schedule</Tab>
          <Tab to="/upcoming-interviews">Upcoming Interviews</Tab>
          <Tab to="/all-interviews">All Interviews</Tab>
        </TabBar>

        <RightContainer>
          <Button onClick={auth.removeAuthToken}>Log Out</Button>

          <HamburgerMenu
            isOpen={isOpen}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MenuContainer isOpen={isOpen}>
              <MenuItem
                to="/schedule"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Schedule
              </MenuItem>

              <MenuItem
                to="/pending-interviews"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Upcoming Interviews
              </MenuItem>

              <MenuItem
                to="/pending-interviews"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                All interviews
              </MenuItem>

              <Divider />

              <MenuItem to="#" onClick={auth.removeAuthToken}>
                Log Out
              </MenuItem>
            </MenuContainer>
          </HamburgerMenu>
        </RightContainer>
      </CenteredContainer>
    </Container>
  );
};

export default TopNavigation;
