import React from 'react';
import styled from 'styled-components';
import SignInForm from 'src/components/SignIn';
import logo from 'src/images/tend-logo.svg';

const AppLogo = styled.img`
  height: 48px;
  margin-right: 15px;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.backgroundApp});
`;
const CenteredContainer = styled.div`
  margin-top: 80px;
  width: 368px;
`;
const Title = styled.h1`
  margin: 24px 0;
  color: var(--color-ink-dark);
`;

const LoginView: React.FC = () => {
  return (
    <Container>
      <CenteredContainer>
        <AppLogo src={logo} alt="tend-logo" />
        <Title>Interviewer Portal</Title>
        <SignInForm />
      </CenteredContainer>
    </Container>
  );
};

export default LoginView;
