import gql from 'graphql-tag';

export const UPDATE_SCHEDULED_INTERVIEW_TYPE = gql`
  mutation UpdateScheduledInterviewType(
    $id: ID!
    $interviewType: ScheduleInterviewType!
  ) {
    updateScheduledInterviewType(id: $id, interviewType: $interviewType) {
      id
      interviewType
    }
  }
`;
