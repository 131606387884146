import styled from 'styled-components';
import Text from 'src/components/Text';

export const Container = styled.form`
  margin: 24px 0;
  padding: 16px;
  border-radius: 3px;
  border: 1px ${({ theme }) => theme.color.supportLines} solid;
`;

export const TitleContainer = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundApp};
  margin: 24px 0;
  padding: 12px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`;

export const QuestionsContainer = styled.div`
  margin: 24px 0;
  padding: 0 16px;
`;

export const SuggestedQuestionsContainer = styled.div`
  margin: 20px 0;
`;

export const TextWithBottomMargin = styled(Text)`
  margin-bottom: 16px;
`;
