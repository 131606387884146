import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const modalElement = document.getElementById('modal-root');

const ModalContainer = styled.div`
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  background: rgba(69, 79, 91, 0.6);
  overflow: scroll;
`;
const ModalCard = styled.div`
  margin: 280px auto;
  width: 50%;
  background-color: ${({ theme }) => theme.color.backgroundSurface};
  padding: 32px;
  border-radius: 6px;
  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    width: 80%;
  })
`;

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  closeOnEscape?: boolean;
};

const Modal: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  closeOnEscape = true,
}) => {
  const handleEscape = useCallback(
    (event) => {
      const escapeKey = 27;
      if (closeOnEscape && event.keyCode === escapeKey) {
        onClose();
      }
    },
    [closeOnEscape, onClose],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEscape, false);
    }
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  return isOpen && modalElement
    ? createPortal(
        <ModalContainer onClick={onClose}>
          <ModalCard
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </ModalCard>
        </ModalContainer>,
        modalElement,
      )
    : null;
};

export default Modal;
