import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  SearchPendingInterviews,
  SearchPendingInterviewsVariables,
} from './__generated__/SearchPendingInterviews';

export const SEARCH_PENDING_INTERVIEWS = gql`
  query SearchPendingInterviews(
    $first: Int!
    $after: String
    $searchTerm: String
    $tenderId: String
    $interviewType: ScheduleInterviewType
  ) {
    searchPendingInterviews(
      first: $first
      after: $after
      searchTerm: $searchTerm
      tenderId: $tenderId
      interviewType: $interviewType
    ) {
      pageInfo {
        totalCount
        remainingEdges
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          createdAt
          startDateTime
          endDateTime
          interviewType
          externalInterviewId
          externalInterviewIds {
            positions
            url
          }
          tender {
            id
            email
            firstName
            lastName
            phoneNumber
            avatarURL
            lastDevice {
              os
            }
            address {
              city
              state
            }
          }
          interviewer {
            id
            email
            firstName
            lastName
            status
          }
          assessment {
            id
            updatedAt
            profilePictureValidated
            completedAt
            finalNotes
            assessmentOutcome
            positionApplications {
              id
              status
              positionSkillRanks {
                positionSkillId
                id
                rank
              }
              position {
                id
                name
              }
            }
            softSkills {
              techSkills
              personality
              polish
              attitude
              workEthic
              reliability
            }
          }
        }
        cursor
      }
    }
  }
`;

export const useSearchPendingInterviewsQuery = (
  options?: QueryHookOptions<
    SearchPendingInterviews,
    SearchPendingInterviewsVariables
  >,
) =>
  useQuery<SearchPendingInterviews, SearchPendingInterviewsVariables>(
    SEARCH_PENDING_INTERVIEWS,
    options,
  );

export const useSearchPendingInterviewsLazyQuery = (
  options?: QueryHookOptions<
    SearchPendingInterviews,
    SearchPendingInterviewsVariables
  >,
) =>
  useLazyQuery<SearchPendingInterviews, SearchPendingInterviewsVariables>(
    SEARCH_PENDING_INTERVIEWS,
    {
      ...options,
      fetchPolicy: 'no-cache',
    },
  );

export default SEARCH_PENDING_INTERVIEWS;
