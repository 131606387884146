import gql from 'graphql-tag';

export const GET_MY_PENDING_INTERVIEWS = gql`
  query MyPendingInterviews($first: Int!, $filters: InterviewFilters) {
    myPendingInterviews(first: $first, filters: $filters) {
      edges {
        node {
          id
          startDateTime
          endDateTime
          interviewType
          externalInterviewId
          externalInterviewIds {
            positions
            url
          }
          interviewer {
            id
            email
            firstName
            lastName
            status
          }
          tender {
            id
            email
            firstName
            lastName
            phoneNumber
            avatarURL
            lastDevice {
              os
            }
            address {
              city
              state
            }
          }
          assessment {
            id
            updatedAt
            profilePictureValidated
            completedAt
            finalNotes
            assessmentOutcome
            positionApplications {
              id
              status
              positionSkillRanks {
                positionSkillId
                id
                rank
              }
              position {
                id
                name
              }
            }
            softSkills {
              techSkills
              personality
              polish
              attitude
              workEthic
              reliability
            }
          }
        }
        cursor
      }
    }
  }
`;
