import { PureQueryOptions, useQuery } from '@apollo/client';
import Tab from '@material/react-tab';
import TabBar from '@material/react-tab-bar';
import {
  endOfDay,
  format,
  parseISO,
  startOfToday,
  startOfTomorrow,
  startOfYesterday,
} from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import InterviewDetail from 'src/components/InterviewDetail';
import IntervieweeCard from 'src/components/IntervieweeCard';
import Modal from 'src/components/Modal';
import Text from 'src/components/Text';
import { InterviewContext, InterviewType } from 'src/contexts/InterviewContext';
import { GET_MY_SCHEDULED_INTERVIEWS } from 'src/graphql/queries/myScheduledInterviews';
import {
  MyInterviews,
  MyInterviews_myScheduledInterviews_edges_node as ScheduledInterview,
} from 'src/graphql/queries/__generated__/MyInterviews';
import {
  BackgroundContainer,
  Container,
  TabContent,
  WhiteBackgroundCard,
} from './Schedule.styled';

type SchedulerProps = {
  isDirty: boolean;
  isInterviewSet: boolean;
  onChange: (interview: InterviewType) => void;
  getMyInterviewsQuery: PureQueryOptions;
  setDate: (date: Date) => void;
};

const Scheduler: React.FC<SchedulerProps> = ({
  isDirty,
  isInterviewSet,
  onChange,
  getMyInterviewsQuery,
  setDate,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [candidates, setCandidates] = useState<ScheduledInterview[]>([]);
  const [selected, setSelected] = useState<string>();
  const { loading, error, data } = useQuery<MyInterviews>(
    getMyInterviewsQuery.query,
    {
      variables: getMyInterviewsQuery.variables,
    },
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [preSelectedInterview, setPreSelectedInterview] =
    useState<ScheduledInterview>();

  useEffect(() => {
    if (data?.myScheduledInterviews.edges) {
      setCandidates(data.myScheduledInterviews.edges.map((edge) => edge.node));
    }
    setSelected(undefined);
  }, [data]);

  const handleActiveIndexUpdate = (index: number): void => {
    setActiveIndex(index);
    switch (index) {
      case 0:
        setDate(startOfYesterday());
        break;
      case 1:
        setDate(startOfToday());
        break;
      case 2:
        setDate(startOfTomorrow());
        break;
    }
  };

  if (loading || error) {
    // TODO(rguinea): Display loader and error if applicable
    return <div />;
  }

  return (
    <>
      <Modal isOpen={modalOpen} onClose={() => null}>
        <ConfirmationDialog
          onSubmit={() => {
            setSelected(preSelectedInterview?.id);
            preSelectedInterview && onChange(preSelectedInterview);
            setModalOpen(false);
          }}
          closeModal={() => {
            setModalOpen(false);
          }}
          title="Please confirm"
          primaryButtonText="Confirm"
        >
          Your unsaved data will be lost. Are you sure you want to continue?
        </ConfirmationDialog>
      </Modal>
      <TabBar
        activeIndex={activeIndex}
        handleActiveIndexUpdate={handleActiveIndexUpdate}
      >
        <Tab data-cy="yesterday-tab">Yesterday</Tab>
        <Tab data-cy="today-tab">Today</Tab>
        <Tab data-cy="tomorrow-tab">Tomorrow</Tab>
      </TabBar>
      <TabContent>
        <Text
          preset="preset4"
          color="var(--color-ink-notasdark)"
          marginBottom={24}
        >
          {candidates.length} candidates
        </Text>
        {candidates.map((candidate) => (
          <div key={candidate.id}>
            <Text
              semiBold
              preset="preset4"
              color="var(--color-ink-notasdark)"
              marginBottom={8}
            >
              {`${format(parseISO(candidate.startDateTime), 'h:mm aa')} -
              ${format(parseISO(candidate.endDateTime), 'h:mm aa')}`}
            </Text>
            <IntervieweeCard
              interview={candidate}
              selected={candidate.id === selected}
              onChange={(i) => {
                if (isInterviewSet && i?.id !== selected && isDirty) {
                  setModalOpen(true);
                  setPreSelectedInterview(i);
                } else {
                  setSelected(i?.id);
                  onChange(i);
                }
              }}
            />
          </div>
        ))}
        {!candidates.length && (
          <>
            <Text semiBold preset="preset4" color="var(--color-ink-notasdark)">
              Booked Time
            </Text>
            <IntervieweeCard onChange={onChange} />
          </>
        )}
      </TabContent>
    </>
  );
};

const Schedule: React.FC = () => {
  const { selectedInterview, setInterview } = useContext(InterviewContext);
  const [date, setDate] = useState<Date>(startOfToday());
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const getMyInterviewsQuery = {
    query: GET_MY_SCHEDULED_INTERVIEWS,
    variables: {
      first: 200,
      after: null,
      filters: {
        from: date,
        to: endOfDay(date),
      },
    },
  };

  const handleSetInterview = (interview: ScheduledInterview | undefined) => {
    setInterview && setInterview(interview);
  };

  useEffect(() => {
    // clear current interview when the view is loaded
    setInterview && setInterview(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BackgroundContainer>
      <Container>
        <Row>
          <Col>
            <Text preset="preset2" margin="12px 0 40px" semiBold>
              Interviewer Dashboard
            </Text>
          </Col>
        </Row>
        <Row gutterWidth={16}>
          <Col xl={4}>
            <Text preset="preset3" marginBottom={12} semiBold>
              Schedule
            </Text>
            <Text
              preset="preset5"
              color="var(--color-ink-notasdark)"
              margin="8px 0 0"
            >
              Applicants are expecting a call within 10 minutes before and after
              scheduled time.
            </Text>
            <WhiteBackgroundCard>
              <Scheduler
                isDirty={isDirty}
                isInterviewSet={!!selectedInterview}
                onChange={handleSetInterview}
                setDate={setDate}
                getMyInterviewsQuery={getMyInterviewsQuery}
              />
            </WhiteBackgroundCard>
          </Col>

          <Col xl={8}>
            <InterviewDetail
              setIsDirty={setIsDirty}
              interview={selectedInterview}
              setInterview={handleSetInterview}
              refetchInterviewsQuery={getMyInterviewsQuery}
            />
          </Col>
        </Row>
      </Container>
    </BackgroundContainer>
  );
};

export default Schedule;
