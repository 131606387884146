import React from 'react';
import Avatar from 'src/components/Avatar';
import MaybeEmptyText from 'src/components/MaybeEmptyText';
import {
  Card,
  DivWithLeftMargin,
  FlexChild,
  FlexContainer,
} from './TenderCard.styled';

import { Tenders_tenders_items as Tender } from '../../graphql/queries/__generated__/Tenders';

type TenderCardProps = {
  tender: Tender;
  onChange?: (tender: Tender) => void;
  selected?: boolean;
};

const TenderCard: React.FC<TenderCardProps> = ({
  selected,
  tender,
  onChange,
}) => {
  const tenderLocation = `${tender.address?.city}, ${tender.address?.state}`;

  return (
    <Card
      pointer={true}
      onClick={() => onChange && tender && onChange(tender)}
      selected={selected}
    >
      <FlexContainer>
        <Avatar
          size={48}
          avatarURL={tender.avatarURL}
          firstName={tender.firstName}
          lastName={tender.lastName}
        />
        <DivWithLeftMargin>
          <MaybeEmptyText
            visible={!!tender}
            preset="preset4"
            width={100}
            marginBottom={4}
          >
            {`${tender.firstName} ${tender.lastName}`}
          </MaybeEmptyText>
          <MaybeEmptyText
            visible={!!tender}
            preset="preset6"
            color="var(--color-ink-notasdark)"
            width={80}
          >
            {tenderLocation}
          </MaybeEmptyText>
        </DivWithLeftMargin>
      </FlexContainer>
      <FlexContainer>
        <FlexChild>
          <MaybeEmptyText
            visible={!!tender}
            preset="preset7"
            color="var(--color-ink-notasdark)"
            width={72}
            marginBottom={4}
          >
            EMAIL
          </MaybeEmptyText>
          <MaybeEmptyText visible={!!tender} preset="preset6P" width={48}>
            {tender.email}
          </MaybeEmptyText>
        </FlexChild>
        <FlexChild>
          <MaybeEmptyText
            visible={!!tender}
            preset="preset7"
            color="var(--color-ink-notasdark)"
            width={72}
            marginBottom={4}
          >
            PHONE NUMBER
          </MaybeEmptyText>
          <MaybeEmptyText visible={!!tender} preset="preset6P" width={48}>
            {tender.phoneNumber}
          </MaybeEmptyText>
        </FlexChild>
      </FlexContainer>
    </Card>
  );
};

export default TenderCard;
