import React, { FC, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import { BlackBorderedButton, ClearButton } from 'src/components/Button';
import Modal from 'src/components/Modal';
import Text from 'src/components/Text';
import { TenderAssessment_tenderAssessment_positionApplications_preinterviewAnswers as PreinterviewAnswersType } from 'src/graphql/queries/__generated__/TenderAssessment';

interface Props {
  positionName: string;
  preinterviewAnswers: PreinterviewAnswersType[];
}

const AnswerText = styled(Text)`
  background: ${({ theme }) => theme.color.backgroundApp};
  border-radius: 4px;
  padding: 8px;
  margin: 16px 0;
`;

const AnswersGroup = styled.div`
  margin-bottom: 20px;
`;

const AnswerSpan = styled.div`
  margin-left: 20px;
`;

const FullAnswersAction = styled.div`
  text-align: right;
  padding-right: 20px;
`;

const Separator = styled.div`
  background-color: var(--color-support-lines);
  height: 2px;
  margin: 20px 0;
`;

const CloseButtonContainer = styled.div`
  text-align: right;
`;

const Bold = styled.span`
  color: ${({ theme }) => theme.color.inkDark};
  font-weight: ${({ theme }) => theme.text.bolder};
  margin-right: 4px;
  display: inline-block;
`;

const PreinterviewAnswers: FC<Props> = ({
  positionName,
  preinterviewAnswers,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Row>
        <Col>
          <Text preset="preset4" semiBold margin="6px 0">
            Previous Answers
          </Text>
        </Col>
        <Col>
          {preinterviewAnswers.length > 1 && (
            <FullAnswersAction>
              <ClearButton
                onClick={(e) => {
                  e.preventDefault();
                  setModalOpen(true);
                }}
              >
                See Pre-Interview Questionnaire Answers
              </ClearButton>
            </FullAnswersAction>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {preinterviewAnswers.length ? (
            <>
              <Text preset="preset4" bold>
                {preinterviewAnswers[0].question.title}
              </Text>
              <Text preset="preset5" color="var(--color-ink-notasdark)">
                {preinterviewAnswers[0].question.text}
              </Text>
              <AnswerText preset="preset5" color="var(--color-ink-notasdark)">
                <Bold>A: </Bold>
                {preinterviewAnswers[0].answers.map((answer, index) =>
                  index ? (
                    <AnswerSpan key={answer}>{answer}</AnswerSpan>
                  ) : (
                    answer
                  ),
                )}
              </AnswerText>
            </>
          ) : (
            <Text preset="preset5">
              No Pre-Interview Questionnaire Answers exist.
            </Text>
          )}
        </Col>
      </Row>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Text preset="preset3" bolder marginBottom={12}>
          Pre-Interview Questionnaire Answers
        </Text>
        <Text
          preset="preset4"
          semiBold
          color="var(--color-primary)"
          marginBottom={22}
        >
          {positionName}
        </Text>
        <Separator />
        {preinterviewAnswers.map((preinterviewAnswer, index) => (
          <div key={preinterviewAnswer.id}>
            {!!index && <Separator />}
            <AnswersGroup>
              <Text preset="preset4" bold>
                {preinterviewAnswer.question.title}
              </Text>
              <Text preset="preset5" color="var(--color-ink-notasdark)">
                {preinterviewAnswer.question.text}
              </Text>
              <AnswerText preset="preset5" color="var(--color-ink-notasdark)">
                <Bold>A: </Bold>
                {preinterviewAnswer.answers.map((answer, answerIndex) =>
                  answerIndex ? (
                    <AnswerSpan key={answer}>{answer}</AnswerSpan>
                  ) : (
                    answer
                  ),
                )}
              </AnswerText>
            </AnswersGroup>
          </div>
        ))}
        <CloseButtonContainer>
          <BlackBorderedButton outlined onClick={() => setModalOpen(false)}>
            Close
          </BlackBorderedButton>
        </CloseButtonContainer>
      </Modal>
    </>
  );
};

export default PreinterviewAnswers;
