import H from 'history';
import jwt_decode from 'jwt-decode';

export type Audience =
  | 'admin'
  | 'customer'
  | 'tender'
  | 'client'
  | 'interviewer';

export interface Authorization {
  sub: string;
  exp?: number;
  aud: Audience[];
  email?: string;
}

const authUris: ReadonlyArray<string> = ['/schedule', '/pending-interviews'];
const publicUris: ReadonlyArray<string> = [
  '/signin',
  '/password_reset',
  '/accept_invitation',
];
const tokenExpirationTolerance = 1000 * 60; // One minute

export const decodeToken = (token: string): Authorization | undefined => {
  try {
    return jwt_decode<Authorization>(token);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const tokenIsValid = (token: string): boolean => {
  let decoded: Authorization;
  try {
    if (!token) {
      return false;
    }
    decoded = jwt_decode<Authorization>(token);
  } catch (error) {
    console.error(error);
    return false;
  }
  if (!decoded.exp) {
    return true;
  }
  const now = Date.now();
  const exp = decoded.exp * 1000; // convert to milliseconds

  return now < exp - tokenExpirationTolerance;
};

export const validateAuthState = (l: H.Location, token: string): boolean => {
  const currentRoute = l.pathname.split('/')[1];
  return !(authUris.includes(`/${currentRoute}`) && !tokenIsValid(token));
};

export const validateUnauthState = (l: H.Location, token: string): boolean => {
  return !(
    token !== '' &&
    publicUris.includes(l.pathname) &&
    tokenIsValid(token)
  );
};
