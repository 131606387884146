import React from 'react';

import {
  AvatarLabel,
  AvatarPlaceholder,
  Container,
  Image,
} from './Avatar.styled';

type DefaultAvatarProps = {
  firstName?: string | null;
  lastName?: string | null;
  bigger: boolean;
};

const DefaultAvatar: React.FC<DefaultAvatarProps> = ({
  firstName,
  lastName,
  bigger,
}) => (
  <AvatarPlaceholder>
    {firstName && lastName && (
      <AvatarLabel bigger={bigger}>
        {firstName.charAt(0).toUpperCase()}
        {lastName.charAt(0).toUpperCase()}
      </AvatarLabel>
    )}
  </AvatarPlaceholder>
);

type Props = {
  avatarURL?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  size?: number;
};

const BIG_AVATAR_SIZE = 100;

const AvatarImage: React.FC<Props> = ({
  avatarURL,
  firstName,
  lastName,
  size = 24,
}) => {
  const bigger = size >= BIG_AVATAR_SIZE;
  return (
    <Container size={size} paddingTop={bigger}>
      {avatarURL ? (
        <Image src={avatarURL} />
      ) : (
        <DefaultAvatar
          firstName={firstName}
          lastName={lastName}
          bigger={bigger}
        />
      )}
    </Container>
  );
};

export default AvatarImage;
