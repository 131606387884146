import React, { FC, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Radio, { NativeRadioControl } from '@material/react-radio';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  BlackBorderedButton,
  DangerButton,
  SubmitButton,
} from 'src/components/Button';
import Modal from 'src/components/Modal';
import Text from 'src/components/Text';
import TextBanner from 'src/components/TextBanner';
import CANCEL_MY_SCHEDULED_INTERVIEW_MUTATION from 'src/graphql/mutations/cancelMyScheduledInterview';
import { CancelMyScheduledInterviewVariables } from 'src/graphql/mutations/__generated__/CancelMyScheduledInterview';
import { CancellationReason } from 'src/__generated__/globalTypes';

interface OptionProps {
  title: string;
  label: string;
  value: string | number | boolean;
  name: string;
  onChange: (value: string) => void;
  checked: boolean;
  'data-cy'?: string;
}

interface CancelInterviewFormProps {
  interviewId: string;
  isUpcoming?: boolean;
  handleCancelInterviewAction?: () => void;
}

const Button = styled(DangerButton)`
  width: 154px;
`;

const OptionContainer = styled.div<{ checked: boolean }>`
  display: flex;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
  background-color: ${({ theme, checked }) =>
    checked ? theme.color.statusPositiveLight : theme.color.backgroundApp};
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.color.primary : theme.color.supportLinesDarker};
`;

const OptionDescription = styled.div`
  margin-left: 6px;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  & .button {
    margin-left: 16px;
  }
`;

const Option: FC<OptionProps> = ({
  title,
  label,
  checked,
  value,
  onChange,
  ...props
}) => {
  const handleOnClick = () => onChange && onChange(`${value}`);

  return (
    <OptionContainer checked={checked} onClick={handleOnClick}>
      <Radio>
        <NativeRadioControl checked={checked} data-cy={props['data-cy']} />
      </Radio>
      <OptionDescription>
        <Text preset="preset6P" bolder>
          {title}
        </Text>
        <Text preset="preset6P">{label}</Text>
      </OptionDescription>
    </OptionContainer>
  );
};

const CancelInterviewForm: FC<CancelInterviewFormProps> = ({
  interviewId,
  isUpcoming,
  handleCancelInterviewAction,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { control, handleSubmit, register, watch, reset, setValue } =
    useForm<CancelMyScheduledInterviewVariables>({
      defaultValues: {
        id: interviewId,
        cancellationReason: CancellationReason.RESCHEDULE_NEEDED,
      },
    });
  useEffect(() => {
    reset({
      id: interviewId,
    });
  }, [reset, interviewId]);

  const [cancelMyScheduledInterview] = useMutation(
    CANCEL_MY_SCHEDULED_INTERVIEW_MUTATION,
  );

  register('id');
  register('cancellationReason');
  const reason = watch('cancellationReason');

  const onSubmit = async (input: CancelMyScheduledInterviewVariables) => {
    await cancelMyScheduledInterview({ variables: input });
    setModalOpen(false);
    handleCancelInterviewAction && handleCancelInterviewAction();
  };

  return (
    <>
      <TextBanner
        preset="preset5P"
        text={
          isUpcoming
            ? 'This interview is still upcoming but you have the ability to cancel or complete it ahead of time.'
            : 'Please call twice. If you cannot reach the applicant wait until ten minutes after the schedule start time and then remove the slot.'
        }
        color="var(--color-status-warning-light)"
        semiBold
      >
        <Button data-cy="remove-slot-button" onClick={() => setModalOpen(true)}>
          Remove Slot
        </Button>
      </TextBanner>
      <Modal isOpen={modalOpen} onClose={() => null}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text preset="preset3" marginBottom={32} bolder>
            Which is the reason for removing this interview slot?
          </Text>
          <Controller
            name="cancellationReason"
            defaultValue={CancellationReason.RESCHEDULE_NEEDED}
            control={control}
            render={({ field: { name, value } }) => (
              <Option
                title="Free Reschedule"
                label="No fault of applicant"
                checked={reason === CancellationReason.RESCHEDULE_NEEDED}
                name={name}
                value={value}
                onChange={() => {
                  setValue(
                    'cancellationReason',
                    CancellationReason.RESCHEDULE_NEEDED,
                  );
                }}
                data-cy="free-reschedule-option"
              />
            )}
          />
          <Controller
            name="cancellationReason"
            defaultValue={CancellationReason.UNREACHABLE}
            control={control}
            render={({ field: { name, value } }) => (
              <Option
                title="Unable to be reached"
                label="The applicant can not be reached. (e.g. no Google Duo, no Facetime)"
                checked={reason === CancellationReason.UNREACHABLE}
                name={name}
                value={value}
                onChange={() => {
                  setValue(
                    'cancellationReason',
                    CancellationReason.UNREACHABLE,
                  );
                }}
                data-cy="unreachable-option"
              />
            )}
          />
          <Controller
            name="cancellationReason"
            defaultValue={CancellationReason.NO_ANSWER}
            control={control}
            render={({ field: { name, value } }) => (
              <Option
                title="No Answer/not ready"
                label="The applicant didn't answer my calls."
                checked={reason === CancellationReason.NO_ANSWER}
                name={name}
                value={value}
                onChange={() => {
                  setValue('cancellationReason', CancellationReason.NO_ANSWER);
                }}
                data-cy="no-answer-option"
              />
            )}
          />
          <FormFooter>
            <BlackBorderedButton
              className="button"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </BlackBorderedButton>
            <SubmitButton
              type="submit"
              className="button"
              data-cy="confirm-button"
            >
              Confirm
            </SubmitButton>
          </FormFooter>
        </form>
      </Modal>
    </>
  );
};

export default CancelInterviewForm;
