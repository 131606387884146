import { createGlobalStyle } from 'styled-components';
import '@material/react-button/dist/button.min.css';
import '@material/react-checkbox/dist/checkbox.min.css';
import '@material/react-text-field/dist/text-field.min.css';
import '@material/react-material-icon/dist/material-icon.min.css';
import '@material/react-radio/dist/radio.min.css';
import '@material/react-snackbar/dist/snackbar.min.css';
import '@material/react-tab-bar/dist/tab-bar.min.css';
import '@material/react-tab-indicator/dist/tab-indicator.min.css';
import '@material/react-tab-scroller/dist/tab-scroller.min.css';
import '@material/react-tab/dist/tab.min.css';

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-brand-tend: #045445;
    --color-primary: #045445;
    --color-primary-active: #2C6C53;
    --color-primary-light: #EAF4EF;
    --color-secondary: #394E6F;
    --color-ink-dark: #333333;
    --color-ink-clear: #FFFFFF;
    --color-ink-notasdark: #6D727C;
    --color-ink-link: #353AC5;
    --color-ink-primary: #045445;
    --color-background-app: #F7F9FB;
    --color-background-surface: #FFFFFF;
    --color-status-destructive: #EB5757;
    --color-status-destructive-light: #fbdddd;
    --color-status-positive: #149262;
    --color-status-positive-light: #E7EEED;
    --color-status-warning: #FF9C00;
    --color-status-warning-light: #FFE3BB;
    --color-status-info: #18A0FB;
    --color-support-lines: #EEE;
    --color-support-lines-darker: #CACBCC;
    --mdc-theme-primary: var(--color-primary);
    --mdc-theme-secondary: var(--color-primary);
    --mdc-theme-on-primary: var(--color-ink-clear);
    --mdc-theme-on-secondary: var(--color-ink-notasdark);
    --mdc-text-field: var(--color-background-app);
    --mdc-text-field--textarea: var(--color-primary-active);
    --mdc-text-field--focused: var(--color-primary);
    --mdc-theme-error: var(--color-status-destructive);
  }

  html, body, #root, #overlay {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: system, -apple-system, BlinkMacSystemFont, 'SFNSText-Regula', 'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;
    font-variant: tabular-nums ;
    line-height: 1.5;
    font-feature-settings: "tnum";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    p, h1, h2, h3, h4, h5, a, input, li, span, div {
     &::selection {
        color: var(--color-ink-clear);
     }
    }
    background-color: var(--color-background-app);

    &.noscroll {
      overflow: hidden;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  ::selection{
    background: var(--color-primary); /* WebKit/Blink Browsers */
  }
`;
