import styled from 'styled-components';

export const Container = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  background-color: ${({ color }) => color};
  padding: 16px;
  margin: 8px 0 0;
  > button {
    margin-left: 24px;
  }
`;
