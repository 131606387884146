import styled from 'styled-components';
import { Container as ReactContainer } from 'react-grid-system';

export const BackgroundContainer = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundApp};
`;

export const Container = styled(ReactContainer)`
  margin-top: 24px;
`;

export const WhiteBackgroundCard = styled.div`
  padding: 0;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color.backgroundSurface};
  margin-top: 24px;
  font-weight: 500;
`;

export const TabContent = styled.div`
  padding: 20px;
`;
