import styled from 'styled-components';
import Button from '@material/react-button';

export const BlackBorderedButton = styled(Button)`
  border-color: ${({ theme }) => theme.color.inkDark}!important;
  color: ${({ theme }) => theme.color.inkDark}!important;
  ${({ theme }) => theme.text.preset5};
  text-transform: none;
  letter-spacing: normal;
  :disabled {
    opacity: 0.4;
  }
  &:hover {
    border-color: ${({ theme }) => theme.color.inkNotasdark}!important;
    color: ${({ theme }) => theme.color.inkNotasdark}!important;
  }
  &:active {
    background-color: ${({ theme }) => theme.color.supportLines}!important;
  }
`;

export const DangerButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.statusDestructive}!important;
  color: ${({ theme }) => theme.color.inkClear}!important;
  ${({ theme }) => theme.text.preset5};
  letter-spacing: normal;
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) =>
      theme.color.statusDestructiveLight}!important;
    color: ${({ theme }) => theme.color.statusDestructive}!important;
  }
  &:active {
    background-color: ${({ theme }) =>
      theme.color.statusDestructiveLight}!important;
    color: ${({ theme }) => theme.color.inkDark}!important;
  }
`;

export const WarningButton = styled(Button)`
  color: ${({ theme }) => theme.color.statusDestructive}!important;
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) =>
      theme.color.statusDestructiveLight}!important;
    color: ${({ theme }) => theme.color.statusDestructive}!important;
  }
  &:active {
    background-color: ${({ theme }) =>
      theme.color.statusDestructiveLight}!important;
    color: ${({ theme }) => theme.color.inkDark}!important;
  }
`;

export const SubmitButton = styled(Button)`
  text-transform: none;
  background-color: ${({ theme }) => theme.color.brandTend}!important;
  color: ${({ theme }) => theme.color.inkClear}!important;
  &:hover {
    background-color: ${({ theme }) => theme.color.primaryLight}!important;
    color: ${({ theme }) => theme.color.brandTend}!important;
  }
  &:active {
    background-color: ${({ theme }) => theme.color.primaryLight}!important;
    color: ${({ theme }) => theme.color.inkDark}!important;
  }
`;

export const ClearButton = styled(Button)`
  color: ${({ theme }) => theme.color.primary}!important;
  ${({ theme }) => theme.text.preset5};
  letter-spacing: normal;
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) => theme.color.primaryLight}!important;
    color: ${({ theme }) => theme.color.brand50}!important;
  }
  &:active {
    background-color: ${({ theme }) => theme.color.primaryLight}!important;
    color: ${({ theme }) => theme.color.inkDark}!important;
  }
`;
