import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { ErrorMessage as HookFormError } from '@hookform/error-message';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import SCTheme from 'src/SCTheme';
import PositionSkillForm, {
  positionSkillMap,
} from 'src/components/PositionSkillForm';
import DefaultPositionFilter from 'src/components/DefaultPositionFilter';
import Modal from 'src/components/Modal';
import PreinterviewAnswers from 'src/components/PreinterviewAnswers';
import RadioGroup, { RadioContainer } from 'src/components/RadioGroup';
import Text from 'src/components/Text';
import TextBanner from 'src/components/TextBanner';
import ErrorMessage from 'src/components/ErrorMessage';
import {
  TenderAssessment,
  TenderAssessment_tenderAssessment_positionApplications_experienceEntries as TenderExperience,
  TenderAssessment_tenderAssessment_positionApplications as PositionApplication,
} from 'src/graphql/queries/__generated__/TenderAssessment';
import { GET_TENDER_ASSESSMENT } from 'src/graphql/queries/tenderAssessment';
import { GET_POSITION_SKILLS } from 'src/graphql/queries/getPositionSkills';
import CREATE_POSITION_APPLICATION from 'src/graphql/mutations/createPositionApplication';
import { GetPositionSkills } from 'src/graphql/queries/__generated__/GetPositionSkills';
import {
  PositionApplicationStatus,
  PositionApplicationInput,
} from 'src/__generated__/globalTypes';

import Chevron from 'src/images/icons/chevron-down.svg';

import {
  BusinessTypeExperienceClickableArea,
  BusinessTypeExperienceContainer,
  BusinessTypeExperienceCountContainer,
  BusinessTypeExperienceEntryContainer,
  BusinessTypeExperienceEntryDates,
  BusinessTypeExperienceEntryName,
  BusinessTypeExperienceListContainer,
  BusinessTypeName,
  CenteredFlexContainer,
  Container,
  Divider,
  PreviousExperienceContainer,
  TitleContainer,
  ButtonContainer,
  DangerButton,
  SubmitButton,
  NewPositionApplicationContainer,
  DisabledButton,
} from './ApplicationForm.styled';

interface Props {
  assessmentId: string;
  disabled: boolean;
  handleReloadInterviewsList?: () => void;
}

interface PreviousExperienceProps {
  disabled: boolean;
  controlName: string;
  experienceEntries: TenderExperience[];
  position: PositionApplication['position'];
}

const ArrowIndicator = styled.img<{ collapsed: boolean }>`
  transform: ${({ collapsed }) => {
    if (collapsed) {
      return 'rotate(-90deg)';
    } else {
      return 'rotate(-360deg)';
    }
  }};
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: inline-flex;
`;

const PreviousExperienceByBusinessType: React.FC<{
  businessTypeName: TenderExperience['businessType']['name'];
  controlName: string;
  disabled: boolean;
  experienceEntries: TenderExperience[];
  experienceEntryIdToIndexMap: Record<string, number>;
  position: PositionApplication['position'];
}> = ({
  businessTypeName,
  controlName,
  disabled,
  experienceEntries,
  experienceEntryIdToIndexMap,
  position,
}) => {
  const { id: positionId, name: positionName } = position;
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [radioGroupValue, setRadioGroupValue] = useState<boolean | undefined>(
    undefined,
  );

  const {
    setValue: setFormValue,
    getValues: getFormValues,
    formState: { errors: formErrors },
    trigger: triggerFormValidation,
  } = useFormContext();

  const { positionApplications } = getFormValues() as {
    positionApplications: PositionApplicationInput[];
  };

  const positionApplicationIndex = positionApplications.findIndex(
    (pa) => pa.positionId === positionId,
  );

  const experienceEntriesInForm: PositionApplicationInput['experienceValidation'] =
    experienceEntries
      .map(
        (experienceEntry) =>
          positionApplications[positionApplicationIndex].experienceValidation[
            experienceEntryIdToIndexMap[experienceEntry.id]
          ],
      )
      .filter((experienceEntry) => experienceEntry !== undefined);

  const key = experienceEntriesInForm.map((ee) => `${ee.validated}`).join('');

  useEffect(() => {
    if (
      experienceEntriesInForm.some(
        (experienceEntry) => experienceEntry.validated === null,
      )
    ) {
      setRadioGroupValue(undefined);
    } else if (
      experienceEntriesInForm.some(
        (experienceEntry) => experienceEntry.validated === false,
      )
    ) {
      setRadioGroupValue(false);
    } else {
      setRadioGroupValue(true);
    }
  }, [controlName, experienceEntriesInForm, key]);

  const errors =
    formErrors.positionApplications?.[positionApplicationIndex]
      .experienceValidation || [];
  const hasErrors = errors.length > 0;

  return (
    <BusinessTypeExperienceContainer>
      <CenteredFlexContainer>
        <BusinessTypeExperienceClickableArea
          onClick={() => setCollapsed(!collapsed)}
        >
          <ArrowIndicator collapsed={collapsed} src={Chevron} />
          <BusinessTypeName preset="preset4">
            {businessTypeName}
          </BusinessTypeName>
          <BusinessTypeExperienceCountContainer>
            <Text
              preset="preset4"
              bold
              color={
                disabled ? SCTheme.color.inkNotasdark : SCTheme.color.inkDark
              }
            >
              {experienceEntries.length}
            </Text>
          </BusinessTypeExperienceCountContainer>
        </BusinessTypeExperienceClickableArea>
        <div>
          <RadioContainer>
            <Text preset="preset6">Validated:</Text>
            <RadioGroup
              value={radioGroupValue}
              onChange={(newValue) => {
                const inputNames = experienceEntries.map(
                  (experienceEntry) =>
                    `positionApplications.${positionApplicationIndex}.experienceValidation.${
                      experienceEntryIdToIndexMap[experienceEntry.id]
                    }.validated`,
                );
                inputNames.forEach((input) => {
                  setFormValue(input, newValue, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                });
                setRadioGroupValue(!!newValue);
                triggerFormValidation(inputNames);
              }}
              inline
              disabled={disabled}
              options={[
                {
                  value: 'true',
                  label: 'Yes',
                  'data-cy': `${positionName.toLowerCase()}-${businessTypeName.toLowerCase()}-exp-yes`,
                },
                {
                  value: 'false',
                  label: 'No',
                  'data-cy': `${positionName.toLowerCase()}-${businessTypeName.toLowerCase()}-exp-no`,
                },
              ]}
            />
          </RadioContainer>
          {hasErrors && (
            <ErrorMessage>{errors[0].validated.message}</ErrorMessage>
          )}
        </div>
      </CenteredFlexContainer>
      <BusinessTypeExperienceListContainer>
        {!collapsed &&
          experienceEntries.map((experienceEntry) => {
            const experienceStartDate = format(
              parseISO(experienceEntry.startDate),
              'MMM yyyy',
            );
            const experienceEndDate = experienceEntry.endDate
              ? format(parseISO(experienceEntry.endDate), 'MMM yyyy')
              : 'Now';

            return (
              <BusinessTypeExperienceEntryContainer key={experienceEntry.id}>
                <BusinessTypeExperienceEntryName>
                  <Text preset="preset5P">{experienceEntry.employer}</Text>
                </BusinessTypeExperienceEntryName>
                <BusinessTypeExperienceEntryDates>
                  <Text preset="preset6" color={SCTheme.color.inkNotasdark}>
                    {experienceStartDate}-{experienceEndDate}
                  </Text>
                </BusinessTypeExperienceEntryDates>
              </BusinessTypeExperienceEntryContainer>
            );
          })}
      </BusinessTypeExperienceListContainer>
    </BusinessTypeExperienceContainer>
  );
};

const PreviousExperience: React.FC<PreviousExperienceProps> = ({
  disabled,
  controlName,
  experienceEntries,
  position,
}) => {
  const experienceEntriesByBusinessType = experienceEntries.reduce<
    Record<TenderExperience['businessType']['name'], TenderExperience[]>
  >((acc, experience) => {
    if (!acc[experience.businessType.name]) {
      acc[experience.businessType.name] = [experience];
    } else {
      acc[experience.businessType.name].push(experience);
    }
    return acc;
  }, {});

  const experienceEntryIdToIndexMap = experienceEntries.reduce<
    Record<string, number>
  >((acc, experience, index) => {
    acc[experience.id] = index;
    return acc;
  }, {});

  return (
    <>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Position Experience
        </Text>
      </TitleContainer>
      <PreviousExperienceContainer>
        {Object.keys(experienceEntriesByBusinessType).map((key, i) => {
          return (
            <PreviousExperienceByBusinessType
              key={key}
              businessTypeName={key}
              controlName={controlName}
              disabled={disabled}
              experienceEntries={experienceEntriesByBusinessType[key]}
              experienceEntryIdToIndexMap={experienceEntryIdToIndexMap}
              position={position}
            />
          );
        })}
      </PreviousExperienceContainer>
    </>
  );
};

type PositionFormProps = {
  disabled: boolean;
  controlName: string;
  positionApplication: PositionApplication;
};
const PositionForm: React.FC<PositionFormProps> = ({
  disabled,
  controlName,
  positionApplication,
}): React.ReactElement => {
  const { data } = useQuery<GetPositionSkills>(GET_POSITION_SKILLS, {
    variables: {
      positionId: positionApplication.position.id,
      paginationOptions: {
        limit: 10,
        page: 1,
      },
    },
  });
  const { register, setValue } = useFormContext();
  useEffect(() => {
    let i = 0;
    data?.positionSkills.items.forEach((positionSkill) => {
      if (!positionSkillMap[positionSkill.key]) {
        return;
      }
      register(`${controlName}.positionSkills.${i}`);
      setValue(`${controlName}.positionSkills.${i}`, {
        positionSkillId: positionSkill.id,
        rank:
          positionApplication.positionSkillRanks.find(
            (positionSkillRank) =>
              positionSkillRank.positionSkillId === positionSkill.id,
          )?.rank || null,
      });
      i++;
    });
  }, [
    controlName,
    data,
    positionApplication.positionSkillRanks,
    register,
    setValue,
  ]);
  return (
    <>
      {(() => {
        let i = 0;
        return data?.positionSkills.items.map((positionSkill) => {
          if (!positionSkillMap[positionSkill.key]) {
            return null;
          }
          const component = (
            <div key={positionSkill.id}>
              <PositionSkillForm
                disabled={disabled}
                positionName={positionApplication.position.name}
                positionSkill={positionSkill}
                controlName={`${controlName}.positionSkills.${i}`}
                rank={
                  positionApplication.positionSkillRanks.find(
                    (positionSkillRank) =>
                      positionSkillRank.positionSkillId === positionSkill.id,
                  )?.rank || null
                }
              />
              <Divider />
            </div>
          );
          i++;
          return component;
        });
      })()}
    </>
  );
};

const AddPositionApplicationButton: React.FC<{
  assessmentId: string;
  tenderId?: string;
  positionApplicationIds?: string[];
  handleReloadInterviewsList?: () => void;
  isDisabled?: boolean;
}> = ({
  tenderId,
  assessmentId,
  positionApplicationIds,
  handleReloadInterviewsList,
  isDisabled = false,
}) => {
  const [open, setIsOpen] = useState<boolean>(false);
  const [newPositionId, setNewPositionId] = useState<string>('');
  const [addNewPositionError, setAddNewPositionError] = useState<string>('');
  const [createPositionApplication] = useMutation(CREATE_POSITION_APPLICATION, {
    refetchQueries: [
      {
        query: GET_TENDER_ASSESSMENT,
        variables: {
          positionId: assessmentId,
        },
      },
    ],
  });
  return (
    <NewPositionApplicationContainer>
      <Tooltip id="disabled-message" />

      {isDisabled && (
        <ButtonWrapper
          data-tooltip-id="disabled-message"
          data-tooltip-content="Assessment was saved and you can't add more positions. Please, create a new one."
        >
          <DisabledButton disabled={true}>
            Add position application
          </DisabledButton>
        </ButtonWrapper>
      )}
      {!isDisabled && (
        <SubmitButton
          onClick={(event) => {
            event.preventDefault();
            setIsOpen(true);
          }}
        >
          Add position application
        </SubmitButton>
      )}
      <Modal isOpen={open} onClose={() => setIsOpen(false)}>
        <Text preset="preset3" bold>
          Create position application
        </Text>
        <DefaultPositionFilter
          onlyInterviewable
          onChange={(positionId) => {
            setNewPositionId(positionId);
            setAddNewPositionError('');
          }}
          hiddenIds={positionApplicationIds}
        />
        {addNewPositionError && (
          <ErrorMessage>{addNewPositionError}</ErrorMessage>
        )}
        <ButtonContainer>
          <DangerButton onClick={() => setIsOpen(false)}>Cancel</DangerButton>
          <SubmitButton
            disabled={!newPositionId}
            onClick={async () => {
              if (!tenderId) {
                return;
              }
              try {
                await createPositionApplication({
                  variables: {
                    tenderId,
                    positionId: newPositionId,
                  },
                });
                setAddNewPositionError('');
                setIsOpen(false);
                handleReloadInterviewsList && handleReloadInterviewsList();
              } catch (e) {
                setAddNewPositionError(
                  'Position application could not be created because there is a previous one approved.',
                );
              }
            }}
          >
            Create
          </SubmitButton>
        </ButtonContainer>
      </Modal>
    </NewPositionApplicationContainer>
  );
};

const ApplicationForm: React.FC<Props> = ({
  assessmentId,
  disabled,
  handleReloadInterviewsList,
}) => {
  const { loading, error, data } = useQuery<TenderAssessment>(
    GET_TENDER_ASSESSMENT,
    {
      variables: {
        positionId: assessmentId,
      },
    },
  );

  // filter the position applications to remove null values in advance
  const positionApplications =
    data?.tenderAssessment.positionApplications.filter((pos) => {
      return pos !== null;
    }) as PositionApplication[];

  const {
    control,
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const positionApplicationsInForm = getValues('positionApplications') as {
    positionId: string;
  }[];

  const positionApplicationFormIndexMap = useMemo(() => {
    return positionApplicationsInForm.reduce<Record<string, number>>(
      (result, application, index) => {
        result[application.positionId] = index;
        return result;
      },
      {},
    );
  }, [positionApplicationsInForm]);

  useEffect(() => {
    positionApplications?.forEach((application) => {
      const { experienceEntries } = application;

      const applicationIndexInForm =
        positionApplicationFormIndexMap[application.position.id];

      experienceEntries.forEach((entry, j) => {
        // TODO: consider refactoring this using `useFieldArray`: https://react-hook-form.com/api/usefieldarray/
        const { __typename, ...experienceValidation } = entry;
        const { businessType, ...experience } = experienceValidation;

        register(
          `positionApplications.${applicationIndexInForm}.experienceValidation.${j}`,
        );

        setValue(
          `positionApplications.${applicationIndexInForm}.experienceValidation.${j}`,
          {
            ...experience,
            businessTypeId: businessType.id,
          },
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, register, setValue, positionApplicationFormIndexMap]);

  if (loading || error) {
    return <div />; // TODO(rguinea) loading state please
  }
  const statusDefaultValue = ['APPROVED', 'REJECTED'].includes(
    data?.tenderAssessment.assessmentOutcome ?? '',
  )
    ? data?.tenderAssessment.assessmentOutcome
    : undefined;

  return (
    <>
      <AddPositionApplicationButton
        assessmentId={assessmentId}
        tenderId={data?.tenderAssessment.tender.id}
        positionApplicationIds={positionApplications.map(
          (application) => application && application.position.id,
        )}
        handleReloadInterviewsList={handleReloadInterviewsList}
        isDisabled={!!data?.tenderAssessment?.completedAt}
      />

      {positionApplications.map((application) => {
        const applicationIndexInForm =
          positionApplicationFormIndexMap[application.position.id];

        return (
          <Container key={application.id}>
            <Text preset="preset4" bold>
              {application.position.name}
            </Text>
            <Text preset="preset6" color="var(--color-ink-notasdark)">
              {application.experienceEntries.length} Company Type
            </Text>
            <Divider />
            <PreinterviewAnswers
              positionName={application.position.name}
              preinterviewAnswers={application.preinterviewAnswers}
            />
            <PositionForm
              disabled={disabled}
              controlName={`positionApplications.${applicationIndexInForm}`}
              positionApplication={application}
            />
            <PreviousExperience
              disabled={disabled}
              controlName={`positionApplications.${applicationIndexInForm}`}
              experienceEntries={application.experienceEntries}
              position={application.position}
            />
            <TextBanner
              preset="preset5P"
              text={`Do you approve candidate for ${application.position.name}?`}
              color="var(--color-primary-light)"
            >
              <RadioContainer>
                <Controller
                  name={`positionApplications.${applicationIndexInForm}.status`}
                  control={control}
                  render={({ field: { ref, ...props } }) => (
                    <RadioGroup
                      {...props}
                      defaultValue={statusDefaultValue}
                      disabled={disabled}
                      inline
                      options={[
                        {
                          value: PositionApplicationStatus.APPROVED,
                          label: 'Approve',
                          'data-cy': 'approve-tender-application',
                        },
                        {
                          value: PositionApplicationStatus.REJECTED,
                          label: 'Deny',
                          'data-cy': 'deny-tender-application',
                        },
                      ]}
                    />
                  )}
                />
              </RadioContainer>
            </TextBanner>
            <HookFormError
              name={`positionApplications.${applicationIndexInForm}.status`}
              errors={errors}
              render={({ message }: { message: string }) => (
                <ErrorMessage>{message}</ErrorMessage>
              )}
            />
          </Container>
        );
      })}
    </>
  );
};

export default ApplicationForm;
