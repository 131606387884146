import styled from 'styled-components';

type GreyRectangleProps = {
  width: number;
  height?: number;
};

export const GreyRectangle = styled.div<GreyRectangleProps>`
  background-color: ${({ theme }) => theme.color.primaryLight};
  height: ${({ height }) => (height ? height : '12')}px;
  width: ${({ width }) => `${width}px`};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${GreyRectangle} {
    margin: 4px;
  }
  margin-bottom: 8px;
`;

export const FlexChild = styled.div``;

type CardProps = {
  pointer: boolean;
  selected?: boolean;
};

export const Card = styled.div<CardProps>`
  ${({ pointer }) => pointer && 'cursor: pointer'};
  padding: 0 24px 24px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
  ${({ selected, theme }) =>
    selected && `box-shadow: 0 0 0 2px ${theme.color.brand50}`};
  border-radius: 6px;
  ${FlexContainer} {
    padding-top: 8px;
  }
  ${FlexChild} + ${FlexChild} {
    margin-left: 40px;
  }
  margin-bottom: 24px;
`;

export const EmptyDiv = styled.div`
  height: 36px;
  float: right;
`;

export const StatusTag = styled.div<{ status: string }>`
  padding: 8px;
  width: max-content;
  background-color: ${({ theme, status }) =>
    status === 'Submitted'
      ? theme.color.statusPositiveLight
      : theme.color.statusWarningLight};
  border-radius: 0 0 3px 3px;
  float: right;
`;

export const InterviewTypeTag = styled.div`
  padding: 8px;
  width: max-content;
  background-color: ${({ theme }) => theme.color.statusPositiveLight};
  border-radius: 0 0 3px 3px;
  float: right;
  margin-right: 3px;
`;

export const DivWithLeftMargin = styled.div`
  margin-left: 24px;
`;

export const DivFloatOnRight = styled.div`
  float: right;
  margin-right: auto;
`;
export const DivFillRemainingSpace = styled.div`
  flex: auto;
`;

export const SelectContainer = styled.div`
  width: 200px;
  margin: 16px 0 24px;
`;
