import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { Input } from '@material/react-text-field';
import * as Yup from 'yup';
import { authContext } from 'src/contexts/AuthContext';
import { SIGNIN_ENDPOINT } from 'src/routes';
import { SubmitButton } from 'src/components/Button';
import { ErrorMessage } from 'src/components/StatusMessages';
import Label from 'src/components/Label';
import PasswordInput from 'src/components/PasswordInput';
import TextField from 'src/components/TextField';
import MaterialIcon from '@material/react-material-icon';

const Link = styled(NavLink)`
  color: ${({ theme }) => theme.color.inkNotasdark};
  margin-bottom: 8px;
  font-size: 14px;
  text-decoration: none;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
`;

type FormValues = {
  email: string;
  password: string;
};

const signinSchema = Yup.object().shape({
  password: Yup.string().required(' '),
  email: Yup.string().email('Invalid email').required(' '),
});

const SignInForm: React.FC = () => {
  const [submitError, setSubmitError] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(signinSchema),
  });
  const auth = useContext(authContext);
  const signInEndpoint = SIGNIN_ENDPOINT;
  const onSubmit = async (values: FormValues) => {
    setSubmitError(false);
    const rawResponse = await fetch(signInEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });
    if (rawResponse.ok) {
      const content = await rawResponse.json();
      auth.setAuthToken(content.authToken);
    } else {
      setSubmitError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Label preset="preset3" required>
          Email
        </Label>
      </Row>
      <Controller
        render={({ field: { ref, ...props } }) => (
          <TextField leadingIcon={<MaterialIcon icon="perm_identity" />}>
            <Input
              {...props}
              data-cy="email-input"
              data-testid="email-input"
              type="email"
              isValid={!errors.email}
            />
          </TextField>
        )}
        name="email"
        defaultValue=""
        control={control}
      />
      <FormErrorMessage errors={errors} name="email" as={<ErrorMessage />} />
      <Row>
        <Label preset="preset3" required>
          Password
        </Label>
        <Link to="/password_reset">Forgot password?</Link>
      </Row>
      <Controller
        render={({ field: { ref, ...props } }) => (
          <PasswordInput
            {...props}
            data-cy="password-input"
            label=" "
            isValid={!errors.password}
            leadingIcon={<MaterialIcon icon="lock" />}
          />
        )}
        defaultValue=""
        control={control}
        name="password"
      />
      {submitError && <ErrorMessage>Wrong email or password</ErrorMessage>}
      <ButtonWrapper>
        <SubmitButton
          data-cy="submit-button"
          data-testid="submit-button"
          type="submit"
          disabled={isSubmitting}
        >
          Sign in
        </SubmitButton>
      </ButtonWrapper>
    </form>
  );
};

export default SignInForm;
