/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssessmentOutcome {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum CancellationReason {
  EXTERNALLY_CANCELLED = 'EXTERNALLY_CANCELLED',
  NO_ANSWER = 'NO_ANSWER',
  RESCHEDULE_NEEDED = 'RESCHEDULE_NEEDED',
  TENDER_CANCELLED = 'TENDER_CANCELLED',
  UNREACHABLE = 'UNREACHABLE',
}

export enum FilterOperator {
  all = 'all',
  any = 'any',
  between = 'between',
  equals = 'equals',
  greater = 'greater',
  less = 'less',
  like = 'like',
  none = 'none',
  not = 'not',
  notlike = 'notlike',
}

export enum InterviewerStatus {
  ACTIVE = 'ACTIVE',
  CAN_ACTIVATE = 'CAN_ACTIVATE',
  NO_EXTERNAL_INVITATION = 'NO_EXTERNAL_INVITATION',
  PENDING = 'PENDING',
  REMOVED_FROM_EXTERNAL_PROVIDER = 'REMOVED_FROM_EXTERNAL_PROVIDER',
}

export enum JobType {
  CONTRACT = 'CONTRACT',
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
}

export enum PositionApplicationStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  IN_REVIEW = 'IN_REVIEW',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
}

export enum PositionCategory {
  INTERVIEW = 'INTERVIEW',
  QUICK_APPLY = 'QUICK_APPLY',
}

export enum ScheduleInterviewType {
  AD_HOC = 'AD_HOC',
  CALENDLY = 'CALENDLY',
  MY_INTERVIEW = 'MY_INTERVIEW',
  SWYTCHBOARD = 'SWYTCHBOARD',
}

export enum TenderStatus {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
  PROBATION = 'PROBATION',
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
}

export interface Filter {
  property: string;
  operator: FilterOperator;
  values: string[];
  nested?: Filter[] | null;
}

export interface InterviewFilters {
  from?: any | null;
  to?: any | null;
}

export interface InterviewerPasswordChangeInput {
  password: string;
}

export interface PaginationOptions {
  limit: number;
  page: number;
}

export interface PositionApplicationInput {
  positionId: string;
  positionSkills: PositionSkillRankInput[];
  experienceValidation: TenderExperienceValidationInput[];
  status: PositionApplicationStatus;
}

export interface PositionSkillRankInput {
  positionSkillId: string;
  rank: number;
}

export interface ScheduledInterviewInput {
  startDateTime: any;
  endDateTime: any;
  tenderId: string;
  interviewerId?: string | null;
  timezone: string;
}

export interface SoftSkillsInput {
  techSkills: number;
  personality: number;
  polish: number;
  attitude: number;
  workEthic: number;
  reliability: number;
}

export interface TenderAssessmentInput {
  id: string;
  profilePictureValidated: boolean;
  positionApplications: PositionApplicationInput[];
  softSkills: SoftSkillsInput;
  finalNotes: string;
}

export interface TenderExperienceValidationInput {
  id?: string | null;
  startDate: any;
  endDate?: any | null;
  employer: string;
  jobType: JobType;
  businessTypeId: string;
  validated: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
