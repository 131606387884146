import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { Input } from '@material/react-text-field';
import * as Yup from 'yup';
import { SubmitButton } from 'src/components/Button';
import Label from 'src/components/Label';
import TextField from 'src/components/TextField';
import Text from 'src/components/Text';
import { ErrorMessage } from 'src/components/StatusMessages';
import { PASSWORD_RESET_EMAIL_ENDPOINT } from 'src/routes';

const MessageContainer = styled.div`
  margin-top: 16px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.backgroundApp};
`;
const CenteredContainer = styled.div`
  margin-top: 80px;
  width: 368px;
`;
const TextWithMargin = styled(Text)`
  margin-bottom: 20px;
`;

type FormValues = {
  email: string;
};

const VerificationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required(''),
});

const VerificationEmailForm: React.FC = () => {
  const [statusMessage, setStatusMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(VerificationSchema),
  });

  const onSubmit = async (values: FormValues) => {
    setStatusMessage(undefined);
    setErrorMessage(undefined);
    const rawResponse = await fetch(PASSWORD_RESET_EMAIL_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });
    if (rawResponse.ok) {
      const successMessage = `We have sent a password reset email to ${values.email}. Please reset your password through the link in this email. If you cannot find it, please check the Junk/Spam folder.`;
      setStatusMessage(successMessage);
    } else {
      setErrorMessage(
        'The server is having issues processing your request. Please try again later',
      );
    }
  };

  return (
    <Container>
      <CenteredContainer>
        <TextWithMargin preset="preset5P">
          Please enter your email address to validate your account and reset
          your password. You may need to check your spam folder.
        </TextWithMargin>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label preset="preset5" required>
            Email
          </Label>
          <Controller
            render={({ field: { ref, ...props } }) => (
              <TextField>
                <Input
                  {...props}
                  data-testid="email-input"
                  type="email"
                  isValid={!formErrors.email}
                />
              </TextField>
            )}
            control={control}
            name="email"
          />
          <FormErrorMessage
            errors={formErrors}
            name="email"
            as={<ErrorMessage />}
          />
          <MessageContainer>
            {statusMessage && <Text preset="preset7">{statusMessage}</Text>}
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </MessageContainer>
          <Container>
            <SubmitButton data-testid="submit-button" type="submit">
              Send email
            </SubmitButton>
          </Container>
        </form>
      </CenteredContainer>
    </Container>
  );
};

export default VerificationEmailForm;
