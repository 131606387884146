import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import Avatar from 'src/components/Avatar';
import MaybeEmptyText from 'src/components/MaybeEmptyText';
import Text from 'src/components/Text';
import { InterviewType } from 'src/contexts/InterviewContext';
import { useMutation } from '@apollo/client';
import {
  Card,
  DivFillRemainingSpace,
  DivFloatOnRight,
  DivWithLeftMargin,
  EmptyDiv,
  FlexChild,
  FlexContainer,
  GreyRectangle,
  InterviewTypeTag,
  StatusTag,
  SelectContainer,
} from './IntervieweeCard.styled';
import Select from '../Select';
import { UPDATE_SCHEDULED_INTERVIEW_TYPE } from '../../graphql/mutations/updateScheduledInterviewType';

const scheduledInterviewTypeLabel = new Map();
scheduledInterviewTypeLabel.set('CALENDLY', 'Calendly');
scheduledInterviewTypeLabel.set('MY_INTERVIEW', 'My Interview');
scheduledInterviewTypeLabel.set('SWYTCHBOARD', 'Swytchboard');

const selectInterviewTypeOptions = [
  { value: 'CALENDLY', label: 'Calendly' },
  { value: 'MY_INTERVIEW', label: 'My Interview' },
  { value: 'SWYTCHBOARD', label: 'Swytchboard' },
];

const Status: React.FC<{ visible: boolean; status?: string }> = ({
  status,
  visible,
}) => {
  if (!visible) {
    return (
      <EmptyDiv>
        <GreyRectangle width={80} height={24} />
      </EmptyDiv>
    );
  }
  const statusText = status ? 'Submitted' : 'Pending';
  const textColor = status ? 'var(--color-primary)' : 'var(--color-ink-dark)';
  return (
    <StatusTag status={statusText}>
      <Text preset="preset6" color={textColor} margin="3px 0" semiBold>
        {statusText}
      </Text>
    </StatusTag>
  );
};

const ScheduledInterviewTypeSelect: React.FC<{
  interview?: InterviewType;
  handleReloadInterviewsList?: () => void;
}> = ({ interview, handleReloadInterviewsList }) => {
  const interviewType = interview?.interviewType || 'CALENDLY';
  const [updateScheduledInterviewType] = useMutation(
    UPDATE_SCHEDULED_INTERVIEW_TYPE,
  );

  return (
    <SelectContainer>
      <Select
        defaultValue={interviewType}
        onChange={async (value) => {
          const result = await updateScheduledInterviewType({
            variables: { id: interview?.id, interviewType: value },
          });

          if (result.data) {
            handleReloadInterviewsList && handleReloadInterviewsList();
          }
        }}
        value={interviewType}
        data-cy="interview-type-select"
        options={selectInterviewTypeOptions}
      />
    </SelectContainer>
  );
};

const ScheduledInterviewType: React.FC<{
  scheduledInterviewType: string | undefined;
}> = ({ scheduledInterviewType = 'CALENDLY' }) => {
  const label = scheduledInterviewTypeLabel.has(scheduledInterviewType)
    ? scheduledInterviewTypeLabel.get(scheduledInterviewType)
    : 'NA';
  return (
    <InterviewTypeTag>
      <Text preset="preset6" color="var(--color-primary)" margin="3px" semiBold>
        {label}
      </Text>
    </InterviewTypeTag>
  );
};

type IntervieweeCardProps = {
  interview?: InterviewType;
  onChange?: (interview: InterviewType) => void;
  small?: boolean;
  selected?: boolean;
  handleReloadInterviewsList?: () => void;
};

const IntervieweeCard: React.FC<IntervieweeCardProps> = ({
  selected,
  interview,
  onChange,
  children,
  small = true,
  handleReloadInterviewsList,
}) => {
  const intervieweeDeviceOs = (() => {
    switch (interview?.tender.lastDevice?.os) {
      case 'android':
        return 'Android';
      case 'ios':
        return 'iOS';
      default:
        return 'Unknown';
    }
  })();

  const intervieweeLocation = `${interview?.tender.address?.city}, ${interview?.tender.address?.state}`;

  return (
    <Card
      pointer={small}
      onClick={() => onChange && interview && onChange(interview)}
      selected={selected}
    >
      {small && (
        <>
          <Status
            status={interview?.assessment.completedAt}
            visible={!!interview}
          />
          {!!interview && (
            <ScheduledInterviewType
              scheduledInterviewType={interview.interviewType}
            />
          )}
        </>
      )}
      <FlexContainer>
        <Avatar
          size={small ? 48 : 100}
          avatarURL={interview?.tender.avatarURL}
          firstName={interview?.tender.firstName}
          lastName={interview?.tender.lastName}
        />
        <DivWithLeftMargin>
          <MaybeEmptyText
            visible={!!interview}
            preset="preset4"
            width={100}
            marginBottom={4}
          >
            {`${interview?.tender.firstName} ${interview?.tender.lastName}`}
          </MaybeEmptyText>
          <MaybeEmptyText
            visible={!!interview}
            preset="preset6"
            color="var(--color-ink-notasdark)"
            width={80}
          >
            {intervieweeLocation}
          </MaybeEmptyText>
        </DivWithLeftMargin>
        {!small && !!interview && (
          <>
            <DivFillRemainingSpace />
            <DivFloatOnRight>
              <ScheduledInterviewTypeSelect
                interview={interview}
                handleReloadInterviewsList={handleReloadInterviewsList}
              />
            </DivFloatOnRight>
          </>
        )}
      </FlexContainer>
      <FlexContainer>
        {!small && (
          <>
            <FlexChild>
              <MaybeEmptyText
                visible={!!interview}
                preset="preset7"
                color="var(--color-ink-notasdark)"
                width={72}
                marginBottom={4}
              >
                EMAIL
              </MaybeEmptyText>
              <MaybeEmptyText
                visible={!!interview}
                preset="preset6P"
                width={48}
              >
                {interview?.tender.email}
              </MaybeEmptyText>
            </FlexChild>
          </>
        )}
        <FlexChild>
          <MaybeEmptyText
            visible={!!interview}
            preset="preset7"
            color="var(--color-ink-notasdark)"
            width={72}
            marginBottom={4}
          >
            PHONE NUMBER
          </MaybeEmptyText>
          <MaybeEmptyText visible={!!interview} preset="preset6P" width={48}>
            {interview?.tender.phoneNumber}
          </MaybeEmptyText>
        </FlexChild>
        {!small && (
          <FlexChild>
            <MaybeEmptyText
              visible={!!interview}
              preset="preset7"
              color="var(--color-ink-notasdark)"
              width={72}
              marginBottom={4}
            >
              PHONE TYPE
            </MaybeEmptyText>
            <MaybeEmptyText visible={!!interview} preset="preset6P" width={48}>
              {intervieweeDeviceOs}
            </MaybeEmptyText>
          </FlexChild>
        )}
        <DivWithLeftMargin>
          <MaybeEmptyText
            visible={!!interview}
            preset="preset7"
            color="var(--color-ink-notasdark)"
            width={72}
            marginBottom={4}
          >
            POSITIONS
          </MaybeEmptyText>

          <MaybeEmptyText visible={!!interview} preset="preset6P" width={152}>
            {interview?.assessment.positionApplications
              .map(
                (positionApplication) =>
                  positionApplication && positionApplication.position.name,
              )
              .join(', ')}
          </MaybeEmptyText>
        </DivWithLeftMargin>
      </FlexContainer>
      {small ? (
        <FlexChild>
          {/* Updated because the date is shown when completedAt has value and that assessment shouldn't be here */}
          <MaybeEmptyText
            visible={!!interview}
            preset="preset6P"
            color="var(--color-ink-notasdark)"
            width={236}
          >
            {!!interview?.assessment.completedAt &&
              `Last Update:  ${format(
                parseISO(interview.assessment.updatedAt),
                'MMM d, yyyy - h:mm aa',
              )}`}
          </MaybeEmptyText>
          <MaybeEmptyText
            visible={!!interview}
            preset="preset6P"
            color="var(--color-ink-notasdark)"
            width={236}
          >
            Interviewer: {interview?.interviewer?.firstName}{' '}
            {interview?.interviewer?.lastName}
            {' <'}
            {interview?.interviewer.email}
            {'>'}
          </MaybeEmptyText>
        </FlexChild>
      ) : (
        interview && children
      )}
    </Card>
  );
};

export default IntervieweeCard;
