import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage as HookFormError } from '@hookform/error-message';
import { GetPositionSkills_positionSkills_items as PositionSkill } from 'src/graphql/queries/__generated__/GetPositionSkills';
import Text from 'src/components/Text';
import RadioGroup, { RadioContainer } from 'src/components/RadioGroup';
import ErrorMessage from 'src/components/ErrorMessage';
import {
  QuestionsContainer,
  TextWithBottomMargin,
  TitleContainer,
  SuggestedQuestionsContainer,
} from './PositionSkillForm.styled';

interface CommonProps {
  disabled: boolean;
  controlName: string;
  rank: number | null;
}
interface FormProps extends CommonProps {
  positionName: string;
  positionSkillName: string;
}
interface Props extends CommonProps {
  positionName: string;
  positionSkill: PositionSkill;
}

const bartenderOptions = [
  {
    value: 0,
    label: '0. No experience',
    'data-cy': 'bartender-option-0',
  },
  {
    value: 1,
    label: '1. Beer & Wine',
    'data-cy': 'bartender-option-1',
  },
  {
    value: 2,
    label: '2. Basic Cocktails',
    'data-cy': 'bartender-option-2',
  },
  {
    value: 3,
    label: '3. Full Bar',
    'data-cy': 'bartender-option-3',
  },
  {
    value: 4,
    label: '4. Mixologist',
    'data-cy': 'bartender-option-4',
  },
];
const cateringOptions = [
  {
    value: 0,
    label: '0. No experience',
    'data-cy': 'catering-option-0',
  },
  {
    value: 1,
    label: '1. Beginner',
    'data-cy': 'catering-option-1',
  },
  {
    value: 2,
    label: '2. Basics',
    'data-cy': 'catering-option-2',
  },
  {
    value: 3,
    label: '3. Solid',
    'data-cy': 'catering-option-3',
  },
  {
    value: 4,
    label: '4. Expert',
    'data-cy': 'catering-option-4',
  },
];

const restaurantOptions = [
  {
    value: 0,
    label: '0. No experience',
    'data-cy': 'restaurant-option-0',
  },
  {
    value: 1,
    label: '1. Counter-serve ($)',
    'data-cy': 'restaurant-option-1',
  },
  {
    value: 2,
    label: '2. Basic Restaurant ($$)',
    'data-cy': 'restaurant-option-2',
  },
  {
    value: 3,
    label: '3. Nice Restaurant ($$$)',
    'data-cy': 'restaurant-option-3',
  },
  {
    value: 4,
    label: '4. Fine Dining ($$$$)',
    'data-cy': 'restaurant-option-4',
  },
];

const banquetOptions = [
  {
    value: 0,
    label: '0. No experience',
    'data-cy': 'banquet-option-0',
  },
  {
    value: 1,
    label: '1. Beginner',
    'data-cy': 'banquet-option-1',
  },
  {
    value: 2,
    label: '2. Light Exp.',
    'data-cy': 'banquet-option-2',
  },
  {
    value: 3,
    label: '3. Solid',
    'data-cy': 'banquet-option-3',
  },
  {
    value: 4,
    label: '4. Solid + 10 top',
    'data-cy': 'banquet-option-4',
  },
];

const lineCookOptions = [
  {
    value: 0,
    label: '0. No experience',
  },
  {
    value: 1,
    label: '1. Questionable',
  },
  {
    value: 2,
    label: '2. Okay',
  },
  {
    value: 3,
    label: '3. Solid',
  },
  {
    value: 4,
    label: '4. Line Cook+',
  },
];
const prepCookOptions = [
  {
    value: 0,
    label: '0. No experience',
  },
  {
    value: 1,
    label: '1. Questionable',
  },
  {
    value: 2,
    label: '2. Okay',
  },
  {
    value: 3,
    label: '3. Solid',
  },
  {
    value: 4,
    label: '4. Prep Cook+',
  },
];
const cashierOptions = [
  {
    value: 0,
    label: '0. No experience',
  },
  {
    value: 1,
    label: '1. Novice / slow',
  },
  {
    value: 2,
    label: '2. Okay',
  },
  {
    value: 3,
    label: '3. Solid',
  },
  {
    value: 4,
    label: '4. Great / Fast',
  },
];
const generalLaborOptions = [
  {
    value: 0,
    label: '0. No interest',
  },
  {
    value: 1,
    label: '1. Not physically taxing',
  },
  {
    value: 2,
    label: '2. Slightly taxing',
  },
  {
    value: 3,
    label: '3. Moderately taxing',
  },
  {
    value: 4,
    label: '4. Very physically taxing',
  },
];
const CateringForm: React.FC<FormProps> = ({
  positionName,
  positionSkillName,
  rank,
  controlName,
  disabled,
}) => {
  const { control, getValues } = useFormContext();
  return (
    <QuestionsContainer>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Suggested Questions
        </Text>
      </TitleContainer>
      {positionSkillName && (
        <Text preset="preset5" bold>
          {positionSkillName}
        </Text>
      )}
      <Text preset="preset6P" color="var(--color-ink-notasdark)">
        Ask if they have any experience working with a catering company or
        serving for catered events. If yes,
      </Text>
      <SuggestedQuestionsContainer>
        <Text preset="preset5P">
          - How many catering events have you worked?
          <br />
          - What kind of events?
          <br />- Walk me through what you would do on one of those events
          (starting from when you arrive, a rough hour-by-hour breakdown)
        </Text>
      </SuggestedQuestionsContainer>
      <TextWithBottomMargin preset="preset5" bolder>
        Rank Competence
      </TextWithBottomMargin>
      <RadioContainer>
        <Controller
          name={`${controlName}.rank`}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <RadioGroup
              {...props}
              defaultValue={rank?.toString()}
              value={getValues(`${controlName}.rank`)}
              disabled={disabled}
              options={cateringOptions.map((option) => ({
                ...option,
                'data-cy': `${positionName.toLowerCase()}-${option['data-cy']}`,
              }))}
            />
          )}
        />
      </RadioContainer>
    </QuestionsContainer>
  );
};
const RestaurantForm: React.FC<FormProps> = ({
  positionName,
  positionSkillName,
  rank,
  controlName,
  disabled,
}) => {
  const { control, getValues } = useFormContext();
  return (
    <QuestionsContainer>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Suggested Questions
        </Text>
      </TitleContainer>
      {positionSkillName && (
        <Text preset="preset5" bold>
          {positionSkillName}
        </Text>
      )}
      <Text preset="preset6P" color="var(--color-ink-notasdark)">
        Ask if they have worked as a waiter / waitress at a restaurant. If yes,
      </Text>
      <SuggestedQuestionsContainer>
        <Text preset="preset5P">
          - What type of restaurant was it? Sit-down? Counter-serve?
          <br />
          - Did you use a point of sale system to input orders?
          <br />- Would you feel comfortable doing fine dining or synchronized
          service?
        </Text>
      </SuggestedQuestionsContainer>
      <TextWithBottomMargin preset="preset5" bold>
        Rank Competence
      </TextWithBottomMargin>
      <RadioContainer>
        <Controller
          name={`${controlName}.rank`}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <RadioGroup
              {...props}
              defaultValue={rank?.toString()}
              value={getValues(`${controlName}.rank`)}
              disabled={disabled}
              options={restaurantOptions.map((option) => ({
                ...option,
                'data-cy': `${positionName.toLowerCase()}-${option['data-cy']}`,
              }))}
            />
          )}
        />
      </RadioContainer>
    </QuestionsContainer>
  );
};
const BanquetForm: React.FC<FormProps> = ({
  positionName,
  positionSkillName,
  rank,
  controlName,
  disabled,
}) => {
  const { control, getValues } = useFormContext();
  return (
    <QuestionsContainer>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Suggested Questions
        </Text>
      </TitleContainer>
      {positionSkillName && (
        <Text preset="preset5" bold>
          {positionSkillName}
        </Text>
      )}
      <Text preset="preset6P" color="var(--color-ink-notasdark)">
        Ask if they have experience as a banquet server. If yes:
      </Text>
      <SuggestedQuestionsContainer>
        <Text preset="preset5P">
          - Where did you work as a banquet server?
          <br />
          - How many events did you do as a banquet server?
          <br />
          - Did you receive training on how to carry 8-10 plates on a tray?
          <br />- Walk me through what you would do in one of these events?
        </Text>
      </SuggestedQuestionsContainer>
      <TextWithBottomMargin preset="preset5" bold>
        Rank Competence
      </TextWithBottomMargin>
      <RadioContainer>
        <Controller
          name={`${controlName}.rank`}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <RadioGroup
              {...props}
              defaultValue={rank?.toString()}
              value={getValues(`${controlName}.rank`)}
              disabled={disabled}
              options={banquetOptions.map((option) => ({
                ...option,
                'data-cy': `${positionName.toLowerCase()}-${option['data-cy']}`,
              }))}
            />
          )}
        />
      </RadioContainer>
    </QuestionsContainer>
  );
};
const BartenderForm: React.FC<FormProps> = ({
  positionName,
  positionSkillName,
  rank,
  controlName,
  disabled,
}) => {
  const { control, getValues } = useFormContext();
  return (
    <QuestionsContainer>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Suggested Questions
        </Text>
      </TitleContainer>
      {positionSkillName && (
        <Text preset="preset5" bold>
          {positionSkillName}
        </Text>
      )}
      <Text preset="preset6P" color="var(--color-ink-notasdark)">
        Pick (or ask the applicant to pick) the most representative bartending
        resume bullet they submitted, and ask the following questions:
      </Text>
      <SuggestedQuestionsContainer>
        <Text preset="preset5P">
          - What kind of drinks were you serving there? Mostly beer &amp; wine,
          basic mixed drinks, craft cocktails?
          <br />
          - What’s the most intricate cocktail you’d feel comfortable making?
          <br />- What are the ingredients and how do you make it?
        </Text>
      </SuggestedQuestionsContainer>
      <TextWithBottomMargin preset="preset5" bold>
        Rank Competence
      </TextWithBottomMargin>
      <RadioContainer>
        <Controller
          name={`${controlName}.rank`}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <RadioGroup
              {...props}
              defaultValue={rank?.toString()}
              value={getValues(`${controlName}.rank`)}
              disabled={disabled}
              options={bartenderOptions.map((option) => ({
                ...option,
                'data-cy': `${positionName.toLowerCase()}-${option['data-cy']}`,
              }))}
            />
          )}
        />
      </RadioContainer>
    </QuestionsContainer>
  );
};
const LineCookForm: React.FC<FormProps> = ({
  positionSkillName,
  rank,
  controlName,
  disabled,
}) => {
  const { control, getValues } = useFormContext();
  return (
    <QuestionsContainer>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Suggested Questions
        </Text>
      </TitleContainer>
      {positionSkillName && (
        <Text preset="preset5" bold>
          {positionSkillName}
        </Text>
      )}
      <Text preset="preset6P" color="var(--color-ink-notasdark)">
        Pick (or ask the applicant to pick) the most representative bartending
        resume bullet they submitted, and ask the following questions:
      </Text>
      <SuggestedQuestionsContainer>
        <Text preset="preset5P">
          - Have you worked as a line cook? Where?
          <br />- Which stations did you work? (Grill? Sautee?)
        </Text>
      </SuggestedQuestionsContainer>
      <TextWithBottomMargin preset="preset5" bold>
        Rank Competence
      </TextWithBottomMargin>
      <RadioContainer>
        <Controller
          name={`${controlName}.rank`}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <RadioGroup
              {...props}
              defaultValue={rank?.toString()}
              value={getValues(`${controlName}.rank`)}
              disabled={disabled}
              options={lineCookOptions}
            />
          )}
        />
      </RadioContainer>
    </QuestionsContainer>
  );
};
const PrepCookForm: React.FC<FormProps> = ({
  positionSkillName,
  rank,
  controlName,
  disabled,
}) => {
  const { control, getValues } = useFormContext();
  return (
    <QuestionsContainer>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Suggested Questions
        </Text>
      </TitleContainer>
      {positionSkillName && (
        <Text preset="preset5" bold>
          {positionSkillName}
        </Text>
      )}
      <Text preset="preset6P" color="var(--color-ink-notasdark)">
        Pick (or ask the applicant to pick) the most representative bartending
        resume bullet they submitted, and ask the following questions:
      </Text>
      <SuggestedQuestionsContainer>
        <Text preset="preset5P">
          - Have you worked as a prep cook? Where?
          <br />- What kind of food were you making?
        </Text>
      </SuggestedQuestionsContainer>
      <TextWithBottomMargin preset="preset5" bold>
        Rank Competence
      </TextWithBottomMargin>
      <RadioContainer>
        <Controller
          name={`${controlName}.rank`}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <RadioGroup
              {...props}
              defaultValue={rank?.toString()}
              value={getValues(`${controlName}.rank`)}
              disabled={disabled}
              options={prepCookOptions}
            />
          )}
        />
      </RadioContainer>
    </QuestionsContainer>
  );
};
const CashierForm: React.FC<FormProps> = ({
  positionSkillName,
  rank,
  controlName,
  disabled,
}) => {
  const { control, getValues } = useFormContext();
  return (
    <QuestionsContainer>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Suggested Questions
        </Text>
      </TitleContainer>
      <Text preset="preset5" bold>
        {positionSkillName}
      </Text>
      <Text preset="preset6P" color="var(--color-ink-notasdark)">
        - Cashier + general labor questions are specialty tags -- before you
        move onto those questions, please ask them the following questions:
      </Text>
      <Text preset="preset5P">
        - Have you worked on any events before? Which ones?
        <br />- What did you do at (one of) those events?
        <br />
        <br />
      </Text>
      <Text preset="preset5" bold>
        Cashier
      </Text>
      <SuggestedQuestionsContainer>
        <Text preset="preset5P">
          - Have you ever worked as a cashier?
          <br />- (If so) where and how long?
        </Text>
      </SuggestedQuestionsContainer>
      <TextWithBottomMargin preset="preset5" bold>
        Rank Competence
      </TextWithBottomMargin>
      <RadioContainer>
        <Controller
          name={`${controlName}.rank`}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <RadioGroup
              {...props}
              defaultValue={rank?.toString()}
              value={getValues(`${controlName}.rank`)}
              disabled={disabled}
              options={cashierOptions}
            />
          )}
        />
      </RadioContainer>
    </QuestionsContainer>
  );
};
const GeneralLaborForm: React.FC<FormProps> = ({
  positionSkillName,
  rank,
  controlName,
  disabled,
}) => {
  const { control, getValues } = useFormContext();
  return (
    <QuestionsContainer>
      <TitleContainer>
        <Text preset="preset4" semiBold>
          Suggested Questions
        </Text>
      </TitleContainer>
      {positionSkillName && (
        <Text preset="preset5" bold>
          {positionSkillName}
        </Text>
      )}
      <SuggestedQuestionsContainer>
        <Text preset="preset5P">
          - Are you interested in working general labor shifts with lots of
          manual labor? (setup / breakdown, warehouse etc.)
          <br />- Are you comfortable working long days on your feet?
        </Text>
      </SuggestedQuestionsContainer>
      <TextWithBottomMargin preset="preset5" bold>
        Rank Competence
      </TextWithBottomMargin>
      <RadioContainer>
        <Controller
          name={`${controlName}.rank`}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <RadioGroup
              {...props}
              defaultValue={rank?.toString()}
              value={getValues(`${controlName}.rank`)}
              disabled={disabled}
              options={generalLaborOptions}
            />
          )}
        />
      </RadioContainer>
    </QuestionsContainer>
  );
};

export const positionSkillMap: Record<string, React.FC<FormProps>> = {
  banquet: BanquetForm,
  catering: CateringForm,
  restaurant: RestaurantForm,
  main: BartenderForm,
  line: LineCookForm,
  prep: PrepCookForm,
  cashier: CashierForm,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  general_labor: GeneralLaborForm,
};

const PositionSkillForm: React.FC<Props> = ({ positionSkill, ...props }) => {
  const Component = positionSkillMap[positionSkill.key];
  if (!Component) {
    return <></>;
  }
  return (
    <>
      <Component {...props} positionSkillName={positionSkill.name} />
      <HookFormError
        name={`${props.controlName}.rank`}
        render={({ message }: { message: string }) => (
          <ErrorMessage>{message}</ErrorMessage>
        )}
      />
    </>
  );
};

export default PositionSkillForm;
