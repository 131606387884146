import styled from 'styled-components';
import { Snackbar as MaterialSnackbar } from '@material/react-snackbar';
import { MD_BREAK_POINT } from 'src/utils/constants';

const Snackbar = styled(MaterialSnackbar)`
  top: 80px;
  bottom: unset;
  .mdc-snackbar__actions {
    max-width: 36px;
    .mdc-snackbar__action {
      min-width: 36px;
      color: var(--color-ink-clear);
      font-size: 22px;
      :after,
      :before {
        background-color: var(--color-ink-clear);
      }
    }
  }
  @media (max-width: ${MD_BREAK_POINT}) {
    top: unset;
    bottom: 0;
  }
`;

export default Snackbar;
