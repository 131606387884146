import React, { useState } from 'react';
import Text from 'src/components/Text';
import Chevron from 'src/images/icons/chevron-down.svg';

import {
  ArrowIndicator,
  Container,
  FlexContainer,
} from './CollapsableCard.styled';

type Props = {
  title: string;
  subTitle: string;
  'data-cy'?: string;
};

const CollapsableCard: React.FC<Props> = ({
  children,
  title,
  subTitle,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Container>
      <FlexContainer onClick={toggleCollapsed}>
        <div>
          <Text
            preset="preset7"
            color="var(--color-ink-notasdark)"
            marginBottom={4}
          >
            {title}
          </Text>
          <Text preset="preset4" bolder color="var(--color-ink-primary)">
            {subTitle}
          </Text>
        </div>
        <ArrowIndicator
          src={Chevron}
          collapsed={collapsed}
          data-cy={props['data-cy']}
        />
      </FlexContainer>
      {!collapsed && children}
    </Container>
  );
};

export default CollapsableCard;
