import gql from 'graphql-tag';

const CANCEL_MY_SCHEDULED_INTERVIEW = gql`
  mutation CancelMyScheduledInterview(
    $id: ID!
    $cancellationReason: CancellationReason!
  ) {
    cancelMyScheduledInterview(id: $id, cancellationReason: $cancellationReason)
  }
`;

export default CANCEL_MY_SCHEDULED_INTERVIEW;
