import gql from 'graphql-tag';

const CREATE_POSITION_APPLICATION = gql`
  mutation CreatePositionApplicationAsInterviewer(
    $tenderId: ID!
    $positionId: ID!
  ) {
    createPositionApplicationAsInterviewer(
      tenderId: $tenderId
      positionId: $positionId
    ) {
      id
      __typename
    }
  }
`;

export default CREATE_POSITION_APPLICATION;
