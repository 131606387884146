import styled from 'styled-components';
import {
  DangerButton as DButton,
  WarningButton as WButton,
  BlackBorderedButton,
} from 'src/components/Button';
import Text from 'src/components/Text';

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px -24px 0;
  padding: 20px 20px 0;
  border-top: 2px ${({ theme }) => theme.color.supportLines} solid;
`;

export const SubmitButtonContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
  justify-content: flex-end;
`;

export const InlineText = styled(Text)`
  display: inline-block;
  margin: 32px 0 16px;
  > p {
    margin: 0;
  }
`;

export const DangerButton = styled(DButton)`
  min-width: 150px;
`;

export const WarningButton = styled(WButton)`
  min-width: 150px;
`;

export const WhiteBackgroundCard = styled.div`
  padding: 0;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color.backgroundSurface};
  margin-top: 24px;
  font-weight: 500;
`;

export const SubTitle = styled(Text)`
  margin: 24px 0 16px;
`;

export const SelectContainer = styled.div`
  width: 320px;
  margin: 16px 0 24px;
`;

export const PullRight = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 32px;
  ${BlackBorderedButton} {
    margin-right: 16px;
  }
`;

export const FormSubmitContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  text-align: right;
`;

export const RedColor = styled.span`
  color: ${({ theme }) => theme.color.statusDestructive};
  font-weight: ${({ theme }) => theme.text.semiBold};
`;

export const ExternalLinksContainer = styled.div`
  margin: 16px 0 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--color-background-app);

  h2 {
    ${({ theme }) => theme.text.preset5};
    margin: 0 0 12px;
  }

  ul {
    display: flex;
    gap: 16px;
    list-style: none;
    margin: 8px 0;
    padding: 0;
  }

  li {
    a {
      background-color: ${({ theme }) => theme.color.primaryLight};
      border-radius: 16px;
      color: ${({ theme }) => theme.color.primary};
      display: block;
      padding: 8px 12px;
      text-decoration: none;

      &:hover,
      &:active {
        box-shadow: 1px 2px 2px ${({ theme }) => theme.color.primary};
      }
    }
  }
`;

export const HiddenSpan = styled.span`
  color: ${({ theme }) => theme.color.backgroundApp};
  font-size: 10px;
`;

export const InlineSpanText = styled.span`
  display: inline-block;
  font-weight: bolder;
`;
