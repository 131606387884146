import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { Input } from '@material/react-text-field';
import * as Yup from 'yup';
import { SubmitButton } from 'src/components/Button';
import { ErrorMessage } from 'src/components/StatusMessages';
import Label from 'src/components/Label';
import PasswordInput from 'src/components/PasswordInput';
import Text from 'src/components/Text';
import TextField from 'src/components/TextField';
import { GET_INTERVIEWER_ENDPOINT, SIGNUP_ENDPOINT } from 'src/routes';
import { MD_BREAK_POINT } from 'src/utils/constants';
import useQueryParams from 'src/hooks/QueryParams';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 48%;
  }
  @media (max-width: ${MD_BREAK_POINT}) {
    display: block;
    > div {
      width: 100%;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const FormContainer = styled.div`
  width: 680px;
  margin: 40px auto;
`;

const Title = styled(Text)`
  margin-bottom: 48px;
`;

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  password: Yup.string()
    .required(' ')
    .min(8, 'Your password must be at least 8 characters.')
    .max(255, 'Your password must be less than 255 characters.'),
  passwordConfirmation: Yup.string()
    .required(' ')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

const getInterviewerData = (token: string | null): Promise<Response> | void => {
  if (!token) {
    return;
  }
  return fetch(GET_INTERVIEWER_ENDPOINT, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

const SignUpForm: React.FC = () => {
  const queryParams = useQueryParams();
  const signUpToken = queryParams.get('token');
  const history = useHistory();
  if (!signUpToken) {
    history.replace('/');
  }
  const {
    control,
    formState: { errors },
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(SignupSchema),
  });
  useEffect(() => {
    const getAndSetInterviewer = async () => {
      const response = await getInterviewerData(signUpToken);
      if (!response) {
        return;
      }
      if (!response.ok) {
        const { message } = await response.json();
        setError(message);
      }
      const interviewer = await response.json();
      reset(interviewer);
    };
    getAndSetInterviewer();
  }, [reset, signUpToken]);

  const [error, setError] = useState(undefined);
  const onSubmit = async (formValues: FormValues) => {
    const { passwordConfirmation, ...values } = formValues;
    const rawResponse = await fetch(SIGNUP_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${signUpToken}`,
      },
      body: JSON.stringify(values),
    });

    if (rawResponse.ok) {
      history.replace('/');
    } else {
      const { message } = await rawResponse.json();
      setError(message);
    }
  };
  return (
    <FormContainer>
      <Title preset="preset3">Fill your information to finish signing up</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer>
          <div>
            <Label preset="preset5" required>
              First Name
            </Label>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <TextField>
                  <Input {...props} type="text" isValid={!errors.firstName} />
                </TextField>
              )}
            />
          </div>
          <div>
            <Label preset="preset5" required>
              Last Name
            </Label>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <TextField>
                  <Input {...props} type="text" isValid={!errors.lastName} />
                </TextField>
              )}
            />
          </div>
        </FlexContainer>
        <Label preset="preset5">Email</Label>
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, ...props } }) => (
            <TextField>
              <Input {...props} disabled type="email" isValid={!errors.email} />
            </TextField>
          )}
        />
        <Label preset="preset5" required>
          Password
        </Label>
        <Controller
          name="password"
          control={control}
          render={({ field: { ref, ...props } }) => (
            <PasswordInput
              {...props}
              label=" "
              name="password"
              isValid={!errors.password}
            />
          )}
        />
        <FormErrorMessage errors={errors} name="password" as={ErrorMessage} />
        <Label preset="preset5" required>
          Confirm Password
        </Label>
        <Controller
          name="passwordConfirmation"
          control={control}
          render={({ field: { ref, ...props } }) => (
            <PasswordInput
              {...props}
              label=" "
              name="passwordConfirmation"
              isValid={!errors.passwordConfirmation}
            />
          )}
        />
        <FormErrorMessage
          errors={errors}
          name="passwordConfirmation"
          as={ErrorMessage}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Container>
          <SubmitButton type="submit" disabled={isSubmitting}>
            Sign up
          </SubmitButton>
        </Container>
      </form>
    </FormContainer>
  );
};

export default SignUpForm;
