import MaterialIcon from '@material/react-material-icon';
import styled from 'styled-components';
import { Card } from '../IntervieweeCard/IntervieweeCard.styled';

export const Container = styled.div`
  position: relative;
  margin-bottom: 6px;

  ${Card} {
    margin-bottom: 0;
    margin-right: 0;
  }

  ${Card} + ${Card} {
    margin-top: 16px;
  }
`;

export const SearchInput = styled.input`
  ${({ theme }) => theme.text.preset6};
  border: 1px solid ${({ theme }) => theme.color.supportLinesDarker};
  border-radius: 4px;
  height: 36px;
  width: 300px;
  padding-left: 34px;

  &:focus {
    outline-color: ${({ theme }) => theme.color.primaryActive};
  }
`;

export const Icon = styled(MaterialIcon)`
  color: ${({ theme }) => theme.color.inkNotasdark};
  height: 20px;
  left: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  line-height: 20px;
`;

export const Popover = styled.dialog`
  background: ${({ theme }) => theme.color.backgroundSurface};
  border: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: 8px;
  max-height: 50vh;
  overflow-y: auto;
  padding: 16px;
  min-width: 460px;
  z-index: 1;
`;

export const Loading = styled.span`
  ${({ theme }) => theme.text.preset6};
  color: ${({ theme }) => theme.color.inkNotasdark};
  text-align: center;
`;

export const Error = styled.p`
  ${({ theme }) => theme.text.preset6};
  color: ${({ theme }) => theme.color.statusDestructive};
`;
