import { PureQueryOptions, useMutation } from '@apollo/client';
import { ErrorMessage as HookFormError } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@material/react-text-field';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import endOfToday from 'date-fns/endOfToday';
import parseISO from 'date-fns/parseISO';
import React, { FC, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import ApplicationForm from 'src/components/ApplicationForm';
import { BlackBorderedButton, SubmitButton } from 'src/components/Button';
import CancelInterviewForm from 'src/components/CancelInterviewForm';
import CollapsableCard from 'src/components/CollapsableCard';
import ErrorMessage from 'src/components/ErrorMessage';
import IntervieweeCard from 'src/components/IntervieweeCard';
import Modal from 'src/components/Modal';
import RadioGroup, { RadioContainer } from 'src/components/RadioGroup';
import Select from 'src/components/Select';
import Snackbar from 'src/components/Snackbar';
import Text from 'src/components/Text';
import TextBanner from 'src/components/TextBanner';
import TextField from 'src/components/TextField';
import { InterviewType } from 'src/contexts/InterviewContext';
import { APPROVE_TENDER_ASSESSMENT } from 'src/graphql/mutations/approveTenderAssessment';
import { DENY_TENDER_ASSESSMENT } from 'src/graphql/mutations/denyTenderAssessment';
import {
  MyInterviews_myScheduledInterviews_edges_node_assessment_softSkills as SoftSkills,
  MyInterviews_myScheduledInterviews_edges_node_tender as Tender,
} from 'src/graphql/queries/__generated__/MyInterviews';
import {
  PositionApplicationStatus,
  TenderAssessmentInput,
} from 'src/__generated__/globalTypes';
import * as yup from 'yup';
import {
  DangerButton,
  ExternalLinksContainer,
  FlexContainer,
  FormSubmitContainer,
  InlineText,
  PullRight,
  RedColor,
  SelectContainer,
  SubmitButtonContainer,
  SubTitle,
  WarningButton,
  WhiteBackgroundCard,
  HiddenSpan,
  InlineSpanText,
} from './InterviewDetail.styled';

interface InterviewDetailProps {
  setIsDirty: (isDirty: boolean) => void;
  interview?: InterviewType;
  setInterview: (interview: InterviewType | undefined) => void;
  refetchInterviewsQuery: PureQueryOptions;
  handleApproveOrDenyAction?: (message: string | undefined) => void;
  handleCancelInterviewAction?: () => void;
  handleReloadInterviewsList?: () => void;
}

type DenyModalProps = {
  disabled: boolean;
  onSubmit: () => void;
  closeModal: () => void;
  tender?: Tender;
};

const schema = yup.object().shape({
  finalNotes: yup.string().required("Please fill the candidate's final notes"),
  id: yup.string().required(),
  profilePictureValidated: yup
    .boolean()
    .required(
      'Please mark if the candidate is the same as in their profile picture',
    ),
  positionApplications: yup.array().of(
    yup.object().shape({
      positionId: yup.string().required(),
      status: yup.string().required('Please approve or deny the candidate'),
      positionSkills: yup.array().of(
        yup.object().shape({
          positionSkillId: yup.string().required(),
          rank: yup
            .number()
            .typeError('Please fill a rank')
            .required('Please fill a rank')
            .integer('Please fill a rank'),
        }),
      ),
      experienceValidation: yup.array().of(
        yup.object().shape({
          validated: yup
            .boolean()
            .required('Please validate the experience')
            .typeError('Please validate the experience'),
        }),
      ),
    }),
  ),
  softSkills: yup.object().shape({
    attitude: yup
      .number()
      .required("Please fill the candidate's attitude")
      .positive("Please fill the candidate's attitude")
      .integer(),
    personality: yup
      .number()
      .required("Please fill the candidate's personality")
      .positive("Please fill the candidate's personality")
      .integer(),
    polish: yup
      .number()
      .required("Please fill the candidate's polish")
      .positive("Please fill the candidate's polish")
      .integer(),
    reliability: yup
      .number()
      .required("Please fill the candidate's reliability")
      .positive("Please fill the candidate's reliability")
      .integer(),
    techSkills: yup
      .number()
      .required("Please fill the candidate's tech skills")
      .positive("Please fill the candidate's tech skills")
      .integer(),
    workEthic: yup
      .number()
      .required("Please fill the candidate's work ethic")
      .positive("Please fill the candidate's work ethic")
      .integer(),
  }),
});

type SoftSkillsProps = {
  disabled: boolean;
  softSkills?: SoftSkills | null;
  finalNotes?: string;
};

const SoftSkillsEvaluation: React.FC<SoftSkillsProps> = ({
  disabled,
  softSkills,
  finalNotes,
}) => {
  const techSkillsOptions = [
    { value: 1, label: '1. Questionable' },
    { value: 2, label: '2. Okay' },
    { value: 3, label: '3. Solid' },
    { value: 4, label: '4. Sharp' },
  ];
  const polishOptions = [
    { value: 1, label: '1. Rough around the edges' },
    { value: 2, label: '2. Fine, maybe not formal' },
    { value: 3, label: '3. Presents well' },
    { value: 4, label: '4. Great for formal event' },
  ];
  const personalityOptions = [
    { value: 1, label: '1. Questionable' },
    { value: 2, label: '2. Okay' },
    { value: 3, label: '3. Solid' },
    { value: 4, label: '4. Great' },
  ];
  const attitudeOptions = [
    { value: 1, label: '1. Me-first' },
    { value: 2, label: '2. Might complain' },
    { value: 3, label: '3. Would get job done' },
    { value: 4, label: '4. Team Player' },
  ];
  const workEthicOptions = [
    { value: 1, label: '1. Questionable' },
    { value: 2, label: '2. Okay' },
    { value: 3, label: '3. Solid' },
    { value: 4, label: '4. Workhorse' },
  ];
  const reliabilityOptions = [
    { value: 1, label: '1. Bit of a wild card' },
    { value: 2, label: '2. Mediocre bet' },
    { value: 3, label: '3. Solid bet' },
    { value: 4, label: '4. Good bet' },
  ];
  const [techSkills, setTechSkills] = useState<string | undefined>(
    softSkills?.techSkills.toString(),
  );
  const [polish, setPolish] = useState<string | undefined>(
    softSkills?.polish.toString(),
  );
  const [personality, setPersonality] = useState<string | undefined>(
    softSkills?.personality.toString(),
  );
  const [attitude, setAttitude] = useState<string | undefined>(
    softSkills?.attitude.toString(),
  );
  const [workEthic, setWorkEthic] = useState<string | undefined>(
    softSkills?.workEthic.toString(),
  );
  const [reliability, setReliability] = useState<string | undefined>(
    softSkills?.reliability.toString(),
  );
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <SubTitle preset="preset4" bold>
        Soft Skills Evaluation
      </SubTitle>
      <Text preset="preset4" semiBold>
        Tech Skills
      </Text>
      <Text preset="preset5">How sharp / tech-savvy is this person?</Text>
      <SelectContainer>
        <Controller
          name="softSkills.techSkills"
          control={control}
          render={({ field: { ref, ...props } }) => (
            <Select
              {...props}
              defaultValue={softSkills?.techSkills.toString()}
              onChange={(val) => {
                setTechSkills(val);
                setValue('softSkills.techSkills', val);
                trigger('softSkills.techSkills');
              }}
              value={techSkills}
              data-cy="tech-skills-select"
              isDisabled={disabled}
              options={techSkillsOptions}
              isSearchable={false}
            />
          )}
        />
        <HookFormError
          name="softSkills.techSkills"
          errors={errors}
          render={({ message }: { message: string }) => (
            <ErrorMessage>{message}</ErrorMessage>
          )}
        />
      </SelectContainer>
      <Text preset="preset4" semiBold>
        Polish
      </Text>
      <Text preset="preset5">How polished is this person?</Text>
      <SelectContainer>
        <Controller
          name="softSkills.polish"
          control={control}
          defaultValue={softSkills?.polish}
          render={({ field: { ref, ...props } }) => (
            <Select
              {...props}
              onChange={(val) => {
                setPolish(val);
                setValue('softSkills.polish', val);
                trigger('softSkills.polish');
              }}
              value={polish}
              data-cy="polish-select"
              isDisabled={disabled}
              options={polishOptions}
              isSearchable={false}
            />
          )}
        />
        <HookFormError
          name="softSkills.polish"
          errors={errors}
          render={({ message }: { message: string }) => (
            <ErrorMessage>{message}</ErrorMessage>
          )}
        />
      </SelectContainer>
      <Text preset="preset4" semiBold>
        Personality / Warmth
      </Text>
      <Text preset="preset5">
        How good is this person with guests / clients?
      </Text>
      <SelectContainer>
        <Controller
          name="softSkills.personality"
          control={control}
          defaultValue={softSkills?.personality}
          render={({ field: { ref, ...props } }) => (
            <Select
              {...props}
              onChange={(val) => {
                setPersonality(val);
                setValue('softSkills.personality', val);
                trigger('softSkills.personality');
              }}
              value={personality}
              data-cy="personality-select"
              isDisabled={disabled}
              options={personalityOptions}
              isSearchable={false}
            />
          )}
        />
        <HookFormError
          name="softSkills.personality"
          errors={errors}
          render={({ message }: { message: string }) => (
            <ErrorMessage>{message}</ErrorMessage>
          )}
        />
      </SelectContainer>
      <Text preset="preset4" semiBold>
        Attitude
      </Text>
      <Text preset="preset5">
        How would this person react to things not going their way?
      </Text>
      <SelectContainer>
        <Controller
          name="softSkills.attitude"
          control={control}
          defaultValue={softSkills?.attitude}
          render={({ field: { ref, ...props } }) => (
            <Select
              {...props}
              onChange={(val) => {
                setAttitude(val);
                setValue('softSkills.attitude', val);
                trigger('softSkills.attitude');
              }}
              value={attitude}
              isDisabled={disabled}
              data-cy="attitude-select"
              options={attitudeOptions}
              isSearchable={false}
            />
          )}
        />
        <HookFormError
          name="softSkills.attitude"
          errors={errors}
          render={({ message }: { message: string }) => (
            <ErrorMessage>{message}</ErrorMessage>
          )}
        />
      </SelectContainer>
      <Text preset="preset4" semiBold>
        Work Ethic
      </Text>
      <Text preset="preset5">
        How hard do you think this person would work?
      </Text>
      <SelectContainer>
        <Controller
          name="softSkills.workEthic"
          control={control}
          defaultValue={softSkills?.workEthic}
          render={({ field: { ref, ...props } }) => (
            <Select
              {...props}
              value={workEthic}
              onChange={(val) => {
                setWorkEthic(val);
                setValue('softSkills.workEthic', val);
                trigger('softSkills.workEthic');
              }}
              data-cy="work-ethic-select"
              isDisabled={disabled}
              options={workEthicOptions}
              isSearchable={false}
            />
          )}
        />
        <HookFormError
          name="softSkills.workEthic"
          errors={errors}
          render={({ message }: { message: string }) => (
            <ErrorMessage>{message}</ErrorMessage>
          )}
        />
      </SelectContainer>
      <Text preset="preset4" semiBold>
        Reliability
      </Text>
      <Text preset="preset5">
        Would you bet on this person to show up + be on time?
      </Text>
      <SelectContainer>
        <Controller
          name="softSkills.reliability"
          control={control}
          defaultValue={softSkills?.reliability}
          render={({ field: { ref, ...props } }) => (
            <Select
              {...props}
              onChange={(val) => {
                setReliability(val);
                setValue('softSkills.reliability', val);
                trigger('softSkills.reliability');
              }}
              value={reliability}
              data-cy="reliability-select"
              isDisabled={disabled}
              options={reliabilityOptions}
              isSearchable={false}
            />
          )}
        />
        <HookFormError
          name="softSkills.reliability"
          errors={errors}
          render={({ message }: { message: string }) => (
            <ErrorMessage>{message}</ErrorMessage>
          )}
        />
      </SelectContainer>
      <Text preset="preset4" bold>
        Final Notes
      </Text>
      <Controller
        name="finalNotes"
        control={control}
        defaultValue={finalNotes}
        render={({ field: { ref, ...props } }) => (
          <TextField textarea label=" ">
            <Input {...props} disabled={disabled} data-cy="final-notes-input" />
          </TextField>
        )}
      />
      <HookFormError
        name="finalNotes"
        errors={errors}
        render={({ message }: { message: string }) => (
          <ErrorMessage>{message}</ErrorMessage>
        )}
      />
    </>
  );
};
const EDITION_DISABLED = 'disabled';

const getEditionTimeLeft = (completedAt: string | undefined): string | null => {
  if (!completedAt) {
    return null;
  }
  const HOURS_TO_FINISH_EDIT = 2;
  const now = new Date();
  const parsedDate = parseISO(completedAt);
  const hourDiff = HOURS_TO_FINISH_EDIT - differenceInHours(now, parsedDate);
  if (hourDiff > 1) {
    return `${hourDiff} hours`;
  }
  const minutesDiff =
    HOURS_TO_FINISH_EDIT * 60 - differenceInMinutes(now, parsedDate);
  if (minutesDiff > 0) {
    return `${minutesDiff} minutes`;
  }
  return EDITION_DISABLED;
};

const InterviewInfo: FC<{
  interview: InterviewType | undefined;
  handleCancelInterviewAction?: () => void;
}> = ({ interview, handleCancelInterviewAction }) => {
  if (!interview) {
    return (
      <Text
        preset="preset5"
        color="var(--color-ink-notasdark)"
        margin="8px 0 0"
      >
        Select a scheduled interview to start evaluating a candidate
      </Text>
    );
  }
  const timeLeft = getEditionTimeLeft(interview.assessment.completedAt);
  const isUpcoming = parseISO(interview.startDateTime) > endOfToday();
  if (timeLeft === null) {
    return (
      <CancelInterviewForm
        interviewId={interview.id}
        isUpcoming={isUpcoming}
        handleCancelInterviewAction={handleCancelInterviewAction}
      />
    );
  }
  if (timeLeft === EDITION_DISABLED) {
    return (
      <Text
        preset="preset5"
        color="var(--color-status-destructive)"
        margin="8px 0 0"
      >
        You can not longer edit this interview.
      </Text>
    );
  }
  return (
    <Text preset="preset5" color="var(--color-ink-notasdark)" margin="8px 0 0">
      Edit the answers for this applicant interview process.{' '}
      <RedColor>You have {timeLeft} left.</RedColor>
    </Text>
  );
};

const DenyCandidateModal: React.FC<DenyModalProps> = ({
  disabled,
  onSubmit,
  tender,
  closeModal,
}) => {
  if (!tender) {
    return null;
  }
  const { firstName, lastName } = tender;
  return (
    <>
      <Text preset="preset3" bold>
        Deny Candidate
      </Text>
      <InlineText preset="preset5P" bold>
        Are you sure you want to deny{' '}
        <InlineSpanText>
          {firstName} {lastName}
        </InlineSpanText>
        ?
      </InlineText>
      <Text preset="preset5P" color="var(--color-ink-notasdark)">
        The candidate won't be able to apply to any position in Tend.
      </Text>
      <PullRight>
        <BlackBorderedButton
          outlined
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            closeModal();
          }}
        >
          Cancel
        </BlackBorderedButton>
        <DangerButton onClick={onSubmit} disabled={disabled}>
          Deny
        </DangerButton>
      </PullRight>
    </>
  );
};

const InterviewDetail: FC<InterviewDetailProps> = ({
  setIsDirty,
  interview,
  setInterview,
  refetchInterviewsQuery,
  handleApproveOrDenyAction,
  handleCancelInterviewAction,
  handleReloadInterviewsList,
}) => {
  const [snackMessage, setSnackMessage] = useState<string>('');
  enum ActiveModal {
    DENY = 'deny',
  }
  const [activeModal, setActiveModal] = useState<ActiveModal | undefined>(
    undefined,
  );

  const methods = useForm<
    TenderAssessmentInput & { submitAction?: 'APPROVE' | 'DENY' }
  >({
    mode: 'onSubmit',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const {
    reset,
    handleSubmit,
    control,
    register,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    watch,
  } = methods;

  register('id');
  register('profilePictureValidated');
  register('softSkills');
  register('positionApplications');
  register('finalNotes');
  register('submitAction');

  const submitAction = watch('submitAction');

  const [approveTenderAssessment, { error: approveError }] = useMutation(
    APPROVE_TENDER_ASSESSMENT,
  );

  const [denyTenderAssessment, { error: denyError }] = useMutation(
    DENY_TENDER_ASSESSMENT,
  );

  const mutationError = approveError || denyError;

  // NOTE: we need to handle the submitAction as a form state value because
  // is not safe to rely on the component state with react-hook-form:
  const onSubmit = async (
    input: TenderAssessmentInput & { submitAction?: 'APPROVE' | 'DENY' },
  ) => {
    delete input.submitAction;

    const successMessage =
      'The candidate profile has been saved successfully! You can edit the information within 24 hours after the first assessment submission.';
    let result;

    // try/catch just to reduce the console error message
    try {
      if (watch('submitAction') === 'APPROVE') {
        result = await approveTenderAssessment({
          variables: { input },
          refetchQueries: [refetchInterviewsQuery],
        });
      } else {
        result = await denyTenderAssessment({
          variables: { input },
          refetchQueries: [refetchInterviewsQuery],
        });
      }
    } catch (e) {
      setActiveModal(undefined);
    }

    if (result?.data) {
      setSnackMessage(successMessage);
      setInterview(undefined);
      handleApproveOrDenyAction && handleApproveOrDenyAction(successMessage);
    }
  };

  const handleSaveAnswersClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setValue('submitAction', 'APPROVE');
    await handleSubmit(onSubmit)();
  };

  const handleDenyCandidateClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();

    await trigger();
    setValue('submitAction', 'DENY');

    const isFormValidated = await trigger();

    if (isFormValidated) {
      setActiveModal(ActiveModal.DENY);
    }
  };

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty, setIsDirty]);

  useEffect(() => {
    if (interview) {
      const profilePictureValidated = interview?.assessment.completedAt
        ? interview?.assessment.profilePictureValidated
        : undefined;
      setValue('finalNotes', interview.assessment.finalNotes || '');
      reset({
        profilePictureValidated,
        id: interview.assessment.id,
        finalNotes: interview.assessment.finalNotes || '',
        softSkills: {
          attitude: interview.assessment.softSkills.attitude,
          personality: interview.assessment.softSkills.personality,
          polish: interview.assessment.softSkills.polish,
          reliability: interview.assessment.softSkills.reliability,
          techSkills: interview.assessment.softSkills.techSkills,
          workEthic: interview.assessment.softSkills.workEthic,
        },
        positionApplications: interview.assessment.positionApplications.map(
          (application) => ({
            status:
              application &&
              application.status !== PositionApplicationStatus.PENDING
                ? application.status
                : undefined,
            positionId: application?.position.id,
            positionSkills: application
              ? application.positionSkillRanks.map((positionSkill) => ({
                  positionSkillId: positionSkill.positionSkillId,
                  rank: positionSkill.rank,
                }))
              : [],
            experienceValidation: [],
          }),
        ),
      });
    }
  }, [interview, reset, setValue]);

  const handleCloseModal = () => setActiveModal(undefined);
  const formDisabled =
    getEditionTimeLeft(interview?.assessment.completedAt) === EDITION_DISABLED;

  return (
    <>
      {snackMessage && (
        <Snackbar
          timeoutMs={5000}
          message={snackMessage}
          actionText="×"
          onClose={() => {
            setSnackMessage('');
          }}
        />
      )}
      <Text preset="preset3" marginBottom={12} semiBold>
        Candidate Profile
      </Text>
      <InterviewInfo
        interview={interview}
        handleCancelInterviewAction={handleCancelInterviewAction}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal
            isOpen={activeModal === ActiveModal.DENY}
            onClose={handleCloseModal}
          >
            <DenyCandidateModal
              disabled={formDisabled}
              onSubmit={handleSubmit(onSubmit)}
              closeModal={handleCloseModal}
              tender={interview?.tender}
            />
          </Modal>
          <WhiteBackgroundCard>
            <IntervieweeCard
              small={false}
              interview={interview}
              handleReloadInterviewsList={handleReloadInterviewsList}
            >
              <FlexContainer>
                <Text preset="preset5">
                  Confirm this is the same person as on the video:
                </Text>
                <RadioContainer>
                  <Controller
                    name="profilePictureValidated"
                    control={control}
                    render={({ field: { ref, ...props } }) => (
                      <RadioGroup
                        {...props}
                        defaultValue={getValues('profilePictureValidated')}
                        value={getValues('profilePictureValidated')}
                        inline
                        disabled={formDisabled}
                        options={[
                          {
                            value: 'true',
                            label: 'Yes',
                            'data-cy': 'validate-pic-yes',
                          },
                          {
                            value: 'false',
                            label: 'No',
                            'data-cy': 'validate-pic-no',
                          },
                        ]}
                      />
                    )}
                  />
                </RadioContainer>
              </FlexContainer>
              <HookFormError
                name="profilePictureValidated"
                errors={errors}
                render={() => (
                  <ErrorMessage>
                    Please fill if the candidate is the same person as on the
                    video
                  </ErrorMessage>
                )}
              />
            </IntervieweeCard>
          </WhiteBackgroundCard>

          {interview && (
            <>
              <Text preset="preset3" semiBold marginBottom={16}>
                Interview Process{' '}
                <HiddenSpan>{interview?.assessment.id}</HiddenSpan>
              </Text>

              {submitAction === 'DENY' && (
                <>
                  {!!Object.keys(errors).length && <ErrorMessage block />}
                  {mutationError && (
                    <ErrorMessage block>
                      {mutationError.message ||
                        'Something went wrong. Please try again'}
                    </ErrorMessage>
                  )}
                </>
              )}

              <TextBanner
                preset="preset5P"
                text="If the applicant is not a fit for Tend, deny applicant
                        for all the positions."
                color="var(--color-status-warning-light)"
              >
                <WarningButton onClick={handleDenyCandidateClick}>
                  Deny Candidate
                </WarningButton>
              </TextBanner>

              <CollapsableCard
                title="ON THE CALL"
                subTitle="Evaluate Positions"
                data-cy="evaluate-positions-accordion"
              >
                {!!interview?.externalInterviewIds?.length && (
                  <ExternalLinksContainer>
                    <h2>Links</h2>

                    <ul>
                      {interview?.externalInterviewIds.map(
                        (externalInterview, index) => (
                          <li key={index}>
                            <a
                              href={externalInterview.url}
                              title={externalInterview.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {externalInterview.positions} ↗
                            </a>
                          </li>
                        ),
                      )}
                    </ul>
                  </ExternalLinksContainer>
                )}

                <ApplicationForm
                  disabled={formDisabled}
                  assessmentId={interview?.assessment.id}
                  handleReloadInterviewsList={handleReloadInterviewsList}
                />
              </CollapsableCard>

              <CollapsableCard
                title="AFTER THE CALL"
                subTitle="General Assessment"
                data-cy="soft-skills-accordion"
              >
                <SoftSkillsEvaluation
                  disabled={formDisabled}
                  softSkills={
                    interview?.assessment.completedAt &&
                    interview?.assessment.softSkills
                  }
                  finalNotes={interview?.assessment.finalNotes}
                />
              </CollapsableCard>
            </>
          )}

          {interview && (
            <FormSubmitContainer>
              {submitAction === 'APPROVE' && (
                <>
                  {!!Object.keys(errors).length && <ErrorMessage block />}
                  {mutationError && (
                    <ErrorMessage block>
                      {mutationError.message ||
                        'Something went wrong. Please try again.'}
                    </ErrorMessage>
                  )}
                </>
              )}
              <SubmitButtonContainer>
                <SubmitButton
                  onClick={handleSaveAnswersClick}
                  disabled={formDisabled}
                  type="button"
                  data-cy="submit-interview-button"
                >
                  Save Answers
                </SubmitButton>
              </SubmitButtonContainer>
            </FormSubmitContainer>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default InterviewDetail;
