import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { Button as ReactButton } from '@material/react-button';
import { MD_BREAK_POINT, NAVBAR_HEIGHT } from 'src/utils/constants';

export const Button = styled(ReactButton)`
  min-width: 88px;
  text-transform: none;
  .mdc-button__label {
    color: ${({ theme }) => theme.color.inkDark};
  }
  @media (max-width: ${MD_BREAK_POINT}) {
    display: none;
  }
`;

export const TabBar = styled.div`
  @media (max-width: ${MD_BREAK_POINT}) {
    display: none;
  }
`;

export const Tab = styled(NavLink)`
  font-size: 18px;
  font-weight: 500;
  max-width: 200px;
  text-align: center;
  margin: 20px;
  color: ${({ theme }) => theme.color.inkNotasdark};
  text-decoration: none;

  &.active {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const RightContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`;

export const CenteredContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 48px;
  height: 100%;
  justify-content: flex-start;
`;

export const ImgLogo = styled.img`
  width: 106px;
`;

export const CenteredLink = styled(Link)`
  margin: 6px 24px 0 0;
`;

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  box-sizing: border-box;
  height: ${NAVBAR_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.backgroundSurface};
  border-bottom: 1px solid ${({ theme }) => theme.color.supportLines};
  padding: 0 16px;
  .mdc-tab-bar {
    padding-top: 4px;
  }
  .mdc-tab {
    max-width: max-content;
    padding: 0 20px;
    margin: 0;
  }
  .mdc-tab__content {
    font-size: 18px;
    font-weight: 500;
  }
  .mdc-tab--active .mdc-tab__content {
    color: ${({ theme }) => theme.color.primary};
  }
  .mdc-tab-indicator {
    display: none;
  }
`;

export const StyledBurger = styled.div`
  cursor: pointer;
  margin-top: 4px;
  @media (min-width: ${MD_BREAK_POINT}) {
    display: none;
  }
`;

export const BurgerLine = styled.span<{ isOpen: boolean }>`
  display: block;
  width: 20px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: black;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.3s cubic-bezier(0.5, 0.2, 0.05, 1),
    background 0.3s cubic-bezier(0.5, 0.2, 0.05, 1), opacity 0.3s ease;
  ${({ isOpen }) =>
    isOpen &&
    `
        opacity: 1;
        transform: rotate(-45deg) translate(0, -1px) scale(1.38, 1);
    `}
  }
  :first-child {
    transform-origin: 0% 0%;
    ${({ isOpen }) =>
      isOpen &&
      'transform: rotate(45deg) translate(-1px, -2px) scale(1.38, 1);'}
  }
  :nth-last-child(2) {
    transform-origin: 0% 100%;
    ${({ isOpen }) =>
      isOpen &&
      `
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      `}
  }
`;

export const MenuItem = styled(NavLink)`
  font-size: 18px;
  height: 40px;
  width: 100%;
  cursor: pointer;
  margin-left: 40px;
  margin-bottom: 8px;
  text-decoration: none;
  color: ${({ theme }) => theme.color.inkNotasdark};
  :hover,
  :active {
    color: ${({ theme }) => theme.color.brandTend};
  }
`;

export const MenuContainer = styled.div<{ isOpen: boolean | undefined }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundApp};
  width: 100vw;
  height: calc(100vh - 64px);
  top: 64px;
  right: 0;
  padding-top: 32px;
  overflow: hidden;
  transition: 0.2s cubic-bezier(0.5, 0.2, 0.05, 1);
  transform-origin: 100% 0;
  transform: scale(${({ isOpen }) => (isOpen ? '1, 1' : '0, 1')});
`;

export const Divider = styled.span`
  border: 1px solid ${({ theme }) => theme.color.supportLines};
  width: 100%;
  margin-bottom: 24px;
`;
