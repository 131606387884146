import React from 'react';
import Text from 'src/components/Text';
import useQueryParams from 'src/hooks/QueryParams';
import PasswordResetEmailForm from 'src/components/PasswordResetEmailForm';
import PasswordResetForm from 'src/components/PasswordResetForm';

const PasswordResetView: React.FC = () => {
  const queryParams = useQueryParams();
  const temporaryToken = queryParams.get('token');

  return (
    <>
      <Text preset="preset3">Reset your password</Text>
      {temporaryToken ? (
        <PasswordResetForm token={temporaryToken} />
      ) : (
        <PasswordResetEmailForm />
      )}
    </>
  );
};

export default PasswordResetView;
