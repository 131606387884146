import styled from 'styled-components';
import { RadioContainer } from 'src/components/RadioGroup';
import {
  SubmitButton as GreenButton,
  DangerButton as RedButton,
} from 'src/components/Button';
import Text from 'src/components/Text';

export const Container = styled.div`
  margin: 24px 0;
  padding: 16px;
  border-radius: 3px;
  border: 1px ${({ theme }) => theme.color.supportLines} solid;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.color.supportLines};
  margin: 24px 0;
  height: 1px;
`;

export const TitleContainer = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundApp};
  margin-top: 24px;
  padding: 12px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`;

export const PreviousExperienceContainer = styled.div`
  > div:last-child {
    padding-bottom: 12px;
  }
`;

export const RadioContainerWithMargin = styled(RadioContainer)`
  margin-right: 16px;
`;

export const CenteredFlexContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const BusinessTypeExperienceClickableArea = styled(
  CenteredFlexContainer,
)`
  cursor: pointer;
`;

export const BusinessTypeExperienceListContainer = styled.div`
  padding-left: 40px;
`;

export const BusinessTypeExperienceContainer = styled.div`
  padding-top: 12px;
`;

export const BusinessTypeExperienceCountContainer = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.color.supportLines};
  border-radius: 8px;
  padding: 0px 8px;
`;

export const BusinessTypeExperienceEntryContainer = styled(
  CenteredFlexContainer,
)`
  margin-bottom: 8px;
`;

export const BusinessTypeExperienceEntryName = styled.div`
  width: 35%;
`;

export const BusinessTypeExperienceEntryDates = styled.div`
  width: 65%;
`;

export const BusinessTypeName = styled(Text)`
  color: ${({ theme }) => theme.color.inkPrimary};
  font-weight: ${({ theme }) => theme.text.bold};
  margin-left: 14px;
  margin-right: 20px;
  user-select: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const DangerButton = styled(RedButton)`
  margin-right: 16px;
`;

export const NewPositionApplicationContainer = styled.div`
  margin-top: 8px;
`;

export const SubmitButton = styled(GreenButton)``;

export const DisabledButton = styled(GreenButton)`
  opacity: 0.5;
`;
