import styled from 'styled-components';

export const ArrowIndicator = styled.img<{ collapsed: boolean }>`
  transform: ${({ collapsed }) => !collapsed && 'rotate(180deg)'};
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSurface};
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 24px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
