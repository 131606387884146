import gql from 'graphql-tag';

export const CREATE_SCHEDULED_INTERVIEW_AS_ADMIN = gql`
  mutation CreateScheduledInterviewAsAdmin($input: ScheduledInterviewInput) {
    createScheduledInterviewAsAdmin(input: $input) {
      id
      startDateTime
      endDateTime
      interviewType
      interviewer {
        id
        email
      }
      tender {
        id
        email
      }
      assessment {
        id
        positionApplications {
          id
          status
          position {
            id
            name
          }
        }
      }
    }
  }
`;
